import Icon from "@mui/material/Icon";
import SignIn from "layouts/authentication/sign-in";
import secureLocalStorage from "react-secure-storage";
import Customer_support from "layouts/customer_support/customer-support";
import Otp_verified from "layouts/customer_support/otp-verified";
import Over_night_verified from "layouts/customer_support/over-night-verified";
import Over_night_not_verified from "layouts/customer_support/over-night-not-verified";
import Lmart_tollfree from "layouts/customer_support/lmart-tollfree";
import Add_enquiry from "layouts/customer_support/add-enquiry";
import Social_enquiry from "layouts/customer_support/social-enquiry";
import Customer_feedback from "layouts/customer_support/customer-feedback";
import Return_leads from "layouts/customer_support/return-lead";
import Add_business_enquiry from "layouts/customer_support/add-business-enquiry";
import No_of_attempts from "layouts/customer_support/no_of_attempts";
import Without_group_enquiry_details from "layouts/customer_support/without-group-enquiry-detial";
import Guest_user from "layouts/customer_support/guest-user";

const emp_id = secureLocalStorage.getItem("emp_id");
const dept_id = secureLocalStorage.getItem("department_id");
const cs_panel_access = secureLocalStorage.getItem("cs_panel_access");
const token = secureLocalStorage.getItem("token");
const permission = secureLocalStorage.getItem("permission");
const parsedPermission = permission ? JSON.parse(permission) : [];

const csroute = [
  {
    type: "collapse",
    name: "Customer Support",
    key: "Customer Support",
    icon: <Icon fontSize="small">account_tree</Icon>,
    nested: [
      {
        route: "/customer-support",
        name: "Logisticmart",
        key: "Logisticmart Customer Support",
        icon: <Icon fontSize="small">published_with_changes</Icon>,
        component: token != null ? <Customer_support name="customer-support" /> : <SignIn />,
      },
    ],
  },
];
if ((dept_id == 4 && cs_panel_access == 'Yes') || dept_id == 9) {
  csroute .push(
    { route: "/customer-support", component: token != null ? <Customer_support name="customer-support" /> : <SignIn /> },
    { route: "/guest-user", component: token != null ? <Guest_user name="guest-user" /> : <SignIn />},
    { route: "/otp-verified", component: token != null ? <Otp_verified name="otp-verified" /> : <SignIn /> },
    { route: "/over-night-verified", component: token != null ? <Over_night_verified name="over-night-verified" /> : <SignIn /> },
    { route: "/over-night-not-verified", component: token != null ? <Over_night_not_verified name="over-night-not-verified" /> : <SignIn /> },
    { route: "/lmart-tollfree", component: token != null ? <Lmart_tollfree name="lmart-tollfree" /> : <SignIn /> },
    { route: "/add-enquiry", component: token != null ? <Add_enquiry name="add-enquiry" /> : <SignIn /> },
    { route: "/add-tollfree-enquiry/:mobile_no", component: token != null ? <Add_enquiry name="add-tollfree-enquiry" /> : <SignIn /> },
    { route: "/edit-enquiry/:enq_id", component: token != null ? <Add_enquiry name="edit-enquiry" /> : <SignIn /> },
    { route: "/social-enquiry", component: token != null ? <Social_enquiry name="social-enquiry" /> : <SignIn /> },
    { route: "/customer-feedback", component: token != null ? <Customer_feedback name="customer-feedback" /> : <SignIn /> },
    { route: "/return-lead", component: token != null ? <Return_leads name="return-lead" /> : <SignIn /> },
    { route: "/add-business-enquiry", component: token != null ? <Add_business_enquiry name="add-business-enquiry" /> : <SignIn /> },
    { route: "/no-of-attempts/:type", component: token != null ? <No_of_attempts name="no-of-attempts" /> : <SignIn /> },
    { route: "/without-group-enquiry-details", component: token != null ? <Without_group_enquiry_details name="without-group-enquiry-details" /> : <SignIn /> }
  );
}

export default csroute;

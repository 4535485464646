import React from 'react';
import { Link, useLocation } from "react-router-dom";
function CsDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    <div>
      <Link style={currentPage === "/customer-support" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/customer-support">
        Fresh Enquiry
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/otp-verified" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/otp-verified">
        OTP Verified
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/over-night-verified" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/over-night-verified">
        Over Night Verified
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/over-night-not-verified" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/over-night-not-verified">
        Over Night Not Verified
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/lmart-tollfree" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/lmart-tollfree">
        Lmart Tollfree
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/social-enquiry" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/social-enquiry">
        Social Enquiry
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/customer-feedback" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/customer-feedback">
        Customer Feedback
      </Link>
      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/return-lead" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/return-lead">
        Return Lead
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/add-business-enquiry" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/add-business-enquiry">
        Business Enq
      </Link>

       &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/no-of-attempts" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to={`/no-of-attempts/${encodeURIComponent('first_attempt')}`}>
        First Attempt
      </Link>

       &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/no-of-attempts" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to={`/no-of-attempts/${encodeURIComponent('second_attempt')}`}>
        Second Attempt
      </Link>

       &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/no-of-attempts" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to={`/no-of-attempts/${encodeURIComponent('third_attempt')}`}>
        Third Attempt
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/without-group-enquiry-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to={`/without-group-enquiry-details`}>
        Without Group Enquiry
      </Link>

      &nbsp;&nbsp;&nbsp;
      <Link style={currentPage === "/guest-user" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end" to={`/guest-user`}>
        Guest User 
      </Link>
    </div>
  );
}

export default CsDetailsHeader;

import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import secureLocalStorage from "react-secure-storage"; 

function TestingData() {
  const employee_id = secureLocalStorage.getItem("emp_id");
  const key = secureLocalStorage.getItem(`show_details_popup_${employee_id}`);
 //alert(key);

  //alert(key);
  const [openRequestModal, setOpenRequestModal] = useState(false);

  useEffect(() => {
    if (key == true) {
      setOpenRequestModal(true);
    }
  }, [key]);

  const close_popup = () => {
    // secureLocalStorage.removeItem(key);
     secureLocalStorage.removeItem(`show_details_popup_${employee_id}`);
    setOpenRequestModal(false);
  };

  return (
    <Dialog
      open={openRequestModal}
      fullWidth
      PaperProps={{ style: { maxWidth: "900px", padding: "20px", height: "500px" } }}
    >
      <DialogTitle style={{ color: "red" }}> How Things Will Work in the CRM:</DialogTitle>
      <DialogContent>
 
 <span style={{color:'black'}}>Login:</span><br/>
 When you sign into the system, your login time will be recorded.
 <br/>
 Login time will not be considered for attendance.
 <br/><br/>
 <span style={{color:'black'}}>Punch In:</span><br/>
 Your punch-in time will start when you are within the office radius or you are connected to the office network.
 <br/>
 You have to click on the punch in button to start your day and this time will be considered for attendance.
 <br/><br/>
 <span style={{color:'black'}}>Logout::</span><br/>
 To log out, click the "Logout" button. A pop-up will appear showing your status for the day and asking you to confirm by selecting "Yes" or "No."
 <br/>
 You must be within the office radius or connected to the office network for your logout time to be recorded. You can still log out, but the time will not be updated.
 <br/><br/>
 <span style={{color:'black'}}>KRA/KPI:</span><br/>
 
 Key Responsibility Areas (KRA) and Key Performance Indicators (KPI) are assigned to you either daily or monthly. You must complete these tasks on a day-to-day or monthly basis. Your attendance status will depend on KRA/KPI accordingly.
 <br/><br/>
 <span style={{color:'black'}}>Attendance:</span><br/>
 Your attendance will be visible on your profile page. You can check your attendance status and history.
 <br/><br/>
 <span style={{color:'black'}}>Idle Time:</span><br/>
 
 Idle time refers to periods when the system detects no activity, meaning you're not working on it. This idle time will be deducted from your working hours.
 <br/><br/>
 <span style={{color:'black'}}>Break:</span><br/>
 
 A break option is available in the top-right corner near the logout button. Click on it to log your break. The break history will be accessible in the drop-down menu on the profile page. Break time will not be counted as idle time.
 <br/><br/>
 <span style={{color:'black'}}>Work From Home (WFH):</span><br/>
 
 After logging in, you must apply for WFH. Your manager will need to allow it before you are can punch in. During WFH, your KRA/KPI tasks will be used to track your attendance. Alternatively, you can send your tasks to your manager for verification and approval.
 
      </DialogContent>
      <DialogActions>
        <button className="btn btn-danger" onClick={close_popup}>
          Close
        </button>
      </DialogActions>
    </Dialog>
  );
}

export default TestingData;

import React,{useState,useEffect} from 'react';
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Form, Button, ProgressBar } from 'react-bootstrap';
// Data
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';
import  secureLocalStorage  from  "react-secure-storage";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";  
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import { TextField } from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import tab from 'assets/theme/components/tabs/tab';

import styled from "styled-components";
import ClientProfileHeader from '../../common_components/client_profile_header';
import { scale } from 'chroma-js';
import docurl from 'layouts/common_components/doc_base_url';
//import CircularProgress from '@mui/material/CircularProgress';
import LoadingModal from 'layouts/loaderModal/LoadingModal';
import { MultiSelect } from "react-multi-select-component";

function ClientProfile()
{
  const { clientId } = useParams();
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
 // const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addModal,setAddModal]=useState(false);
  const [clientFollowup, setClientFollowup] = useState();
  const [validationErrors, setValidationErrors] = useState({});
  
  //const [clientDetails,setClientDetails] = useState();
  const [documentType,setDocumentType] = useState([]);
  const [orgType,setOrgType] = useState();
  const [matureClientDetails,setMatureClientDetails] = useState();
  const [soldPackages,setSoldPackages] =useState('');

  const [orgTypeId,setOrgTypeId]=useState('');
  const [clientName,setClientName]=useState('');
  const [businessName,setBusinessName]=useState('');
  const [clientEmail,setClientEmail]=useState('');
  const [clientMobile,setClientMobile]=useState('');
  const [clientCity,setClientCity]=useState('');
  const [clientState,setClientState]=useState('');
  //const [clientPinNo,setClientPinNo]=useState('');
  const [clientAddress,setClientAddress]=useState('');
  const [clientPanCardNo,setPanCardNo]=useState('');

 
  const [clientZipcode, setZipCode] = useState('');
  const [selectedProposalId, setProposalId] = useState('');

  const [searchTerm, setSearchTerm] = useState(''); // State to hold the search input value
  //const [selectedPackage, setSelectedPackage] = useState(''); // State for selected package
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const handleChange = (e) => {
  //   setZipCode(e.target.value);
  // };

  const handleChange = (e) => {
    const value = e.target.value;

    // Allow only numbers
    if (/^\d{0,6}$/.test(value)) {
      setZipCode(value);
      setValidationErrors({ ...validationErrors, clientZipcode: '' });
    }

    // Validate if the entered value is not exactly 6 digits
    if (value.length > 0 && value.length !== 6) {
      setValidationErrors({ ...validationErrors, clientZipcode: 'ZIP code must be exactly 6 digits.' });
    } else {
      setValidationErrors({ ...validationErrors, clientZipcode: '' });
    }
  };



  const handleCompanyZipcode = (e) => {
    const value = e.target.value;

    // Allow only numbers and restrict to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setCompanyZipcode(value);
      
      // Set validation error if not exactly 6 digits
      if (value.length === 6 || value.length === 0) {
        setValidationErrors({ ...validationErrors, companyZipcode: '' });
      } else {
        setValidationErrors({ ...validationErrors, companyZipcode: 'ZIP code must be exactly 6 digits.' });
      }
    }
  };

  // Function to validate ZIP code
  const validateZipCode = (zip) => {
    // Regular expression to match 5-digit or 5-4 digit US ZIP codes
    const zipRegex = /^\d{6}(\d{4})?$/;
    return zipRegex.test(zip);
  };  

  const [isDivShow,setDivShow] = useState(false);
  const [followup,setFollowup] = useState([]);
  const [nextFollowupDate, setNextFollowupDate] = useState('');
 
  const [follwoupModal,setFollowupModal] = useState(false);
  const [selectedFollowCallStatus,setFollCallStatus] = useState(''); 
  const [selectedFollowStatus,setFollStatus] = useState('');
  const [followupRemarks , setFollowupRemarks] = useState('');
  const [nextDays,setNextDays] = useState(0);
  
  const [nxtFollowupDate, setNxtFollowupDate] = useState('');
  const myDate = new Date(nxtFollowupDate);
  //const nxtFolDate= myDate.toLocaleDateString();
  const year = myDate.getFullYear(); 
  const month = String(myDate.getMonth() + 1).padStart(2, '0');
  const day = String(myDate.getDate()).padStart(2, '0');
  const nxtFolDate = `${year}-${month}-${day}`;

  const [packageby,setpackageby] = useState(false);
  const [prePackageDetails, setLmartPrePackageDetails] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState(prePackageDetails);
  const [packagedetails,setpackagedetails] = useState({});

  const [walletHistory, setWalletHistory] =  useState();

  const [cityList,setCityList] = useState([]);
  const [selectedCity,setSelectedCity] = useState('');

  const [product,setProduct]=useState([]);
  const [selectedProduct,setSelectedProduct] = useState('');

  const [service,setService]=useState([]);
  const [selectedService,setSelectedService] = useState('');
    
  const [category,setCategory]=useState([]);
  const [selectedCategory,setSelectedCategory] = useState('');

  const [prePackageList,setPackageList] = useState([]);
  const [selectedPackage,setSelectedPackage] = useState('');
  
  const [salePackageInfo,setSalePackageList] = useState({});
  const [walletDetails,setWalletDetails] = useState({});

  const [selectedRegAmount,setRegistrationAmount] = useState('');
  const [selectedGstPercent,setGstPercent] = useState('');
  const [groupList,setGroupList] = useState('');
  const [selectedGroup,setSelectedGroup]= useState('');

  const [selectedDocumentType,setSelectedDocumentType]=useState('');
  const [selectedDocumentNo,setSelectedDocument]=useState('');
  const [documentFile,setDocumentFile]=useState([]);
  const [clientDocDetails,setUplodedDocuments]=useState([]);

  const [businessEmail,setBusinessEmail]=useState('');
  const [businessMobile,setBusinessMobile]=useState('');
  const [companyCity,setCompanyCity]=useState('');
  const [companyState,setCompanyState]=useState('');
  const [companyAddress,setCompanyAddress]=useState('');
  const [companyPanCardNo,setCompanyPanCardNo]=useState('');
  const [companyZipcode,setCompanyZipcode]=useState('');
  
  
  const [packageCategory,setPackageCategory]=useState([]);
  const [prePackageId, setPrePackageId]=useState('');
  const [prePackages,setPrePackages]=useState('');
  const [gstStateCode, setGstStateCode] = useState("");
  const [packageloading, setPackageLoading] = useState(false);
  const [viewOfferDetailsStatus, setViewOfferDetails] = useState(0);
  const [selectedSalePackageId, setSalePackageId] = useState('');

  //console.log(clientDocDetails.length);
  const handleFileChange = (e) => {
    setDocumentFile(e.target.files);
  };

  const[gstNumber,setGstNumber] = useState('');

  const [pkgTypeList,setPkgTypeList] = useState();
  const [selectedPkgType,setSelectedPkgType]= useState();
  //const [selectedDocument,setSelectedDocument] = useState([]);
  //const [selectedDoc, setDocNo] = useState();
  
  const [companyGstNumber,setCompanyGstNumber] = useState("");
  const [gstOption, setGstOption] = useState(""); 


    const [sameAsClientInfo, setSameAsClientInfo] = useState(false);
  
  
    // Handle the checkbox toggle
    const handleSameAsClientInfo = (e) => {
      const isChecked = e.target.checked;
      setSameAsClientInfo(isChecked);
      // If checked, copy permanent address to communication address
      if(isChecked) {
       
        setBusinessName(clientName);
        setBusinessEmail(clientEmail);
        setBusinessMobile(clientMobile);
        setCompanyCity(clientCity);
        setCompanyState(clientState);
        setCompanyAddress(clientAddress);
        setCompanyPanCardNo(clientPanCardNo);
        setCompanyZipcode(clientZipcode);
        setBusinessEmail(clientEmail);
        setGstStateCode(gstStateCode);
      }
      else
      {
        setBusinessName('');
        setBusinessEmail('');
        setBusinessMobile('');
        setCompanyCity('');
        setCompanyState('');
        setCompanyAddress('');
        setCompanyPanCardNo('');
        setCompanyZipcode('');
        setBusinessEmail('');
        //setGstStateCode('');
      }
    };

    const viewOfferDetails = async (id, category_name, service_name, duration_name, price, name, total_lead, is_partial_payment, package_name, city_id, group_id) => 
    {
      setSalePackageId(id);
      setViewOfferDetails(1);
      setLoading(true);
      try {
        const response = await dashboardApi.get('check-offer-details', {
          params: {
            id: id,
            category_name: category_name,
            service_name: service_name,
            duration_name: duration_name,
            price: price,
            name: name,
            total_lead: total_lead,
            client_id: clientId,
            isPartialPayment: is_partial_payment,
            package_name: package_name,
            city_id: city_id,
            group_id: group_id
          }
        });
  
        if (response.data.status === 200) {
  
          setpackagedetails(response.data.data);
          //setPackageCityList(response.data.data.cityDetails);
          setSelectedPackageCity(response.data.data.city_id);
          setpackageby(true);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
  
      }
      catch (error) {
        console.error('Error fetching data:', error);
      }
    }
   
  const getPackageCategory = async()=>
  {
    const response = await dashboardApi.get('get-package-category',{
      params:{
        product_id:1
      }})

      if(response.data.status === 200)
      {
        setPackageCategory(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
  }

  useEffect(()=>
  { 
    const getPackageCategoryById =async()=>
    {
      const response = await dashboardApi.get('get-pre-package-by-category',{
        params:{
          product_id:selectedProduct,
          category_id:prePackageId,
          group:selectedCity,
          service:selectedService,
        }})

        if(response.data.status === 200)
        {
          setPrePackages(response.data.data);
        }
        else{
          console.error('Error fetching countries:', response.data.message);
        }
        setLoading(false);
    }
    getPackageCategoryById();
  },[prePackageId,selectedProduct,selectedCity,selectedService])

  

  const handleFilter = (event) => {
    const value = event.target.value;
    const filtered = prePackageDetails.filter(category => category.category_name.includes(value));
      setFilteredUsers(filtered);
  };

  const categoryNameFilter = prePackageDetails.map(q => q.category_name);
  categoryNameFilter.filter((q, idx) => categoryNameFilter.indexOf(q) !== idx);
 // const [depositAmount,setDepositAmount]= useState('');

  const [deposit_amount , setDepositAmountByClient] = useState('');

    const getGroupList = async () =>
    {
        try
        {
             const response = await dashboardApi.get(`get-group-list`);
            if (response.data.status === 200)
            {
                setGroupList(response.data.data);
            } 
        }
        catch(error)
        {
            console.error('Error fetching countries:', error);
        }
    }


    const handleStateChange = (e) => {
      const selectedState = e.target.value;
      
      setClientState(selectedState);
      // Find the GST code based on the selected state
      const selectedStateObj = stateList.find(
        (stateInfo) => String(stateInfo.id) === selectedState  // Convert both to string for comparison
      );
    
      if (selectedStateObj) {
        console.log("Selected State GST Code:", selectedStateObj.gst_state_code);
        setGstStateCode(selectedStateObj.gst_state_code);
      } else {
        setGstStateCode(gstStateCode); // Clear GST code if no valid state is selected
      }
    };


    const handleStateChangeCompany = (e) => {
      const selectedState = e.target.value;
      setCompanyState(selectedState);
      // Find the GST code based on the selected state
      const selectedStateObj = stateList.find(
        (stateInfo) => String(stateInfo.id) === selectedState  // Convert both to string for comparison
      );
    
      if (selectedStateObj) {
        console.log("Selected State GST Code:", selectedStateObj.gst_state_code);
        setGstStateCode(selectedStateObj.gst_state_code);
      } else {
        setGstStateCode(gstStateCode); // Clear GST code if no valid state is selected
      }
    };

    const getClientUplodedDocument = async () =>
    {
        try
        {
              const response = await dashboardApi.get(`get-client-uploded-document/${clientId}`);
            if (response.data.status === 200)
            {
                setUplodedDocuments(response.data.data);
            } 
        }
        catch(error)
        {
            console.error('Error fetching countries:', error);
        }
    }
   

    const handleDocumentTypeChange = (e) => {
      const selectedType = e.target.value;
      setSelectedDocumentType(selectedType);
    
      if (selectedType === '1') {
        setSelectedDocument(companyPanCardNo); // Set the PAN card number
      } else if (selectedType === '4') {
        setSelectedDocument(companyGstNumber); // Set the GST number
      } else {
        setSelectedDocument(''); // Reset or clear the document number for other types
      }
    };

    const uplodeDocument = async(e) =>
    {
          e.preventDefault();
          const errors = {};
          if(!selectedDocumentType)
          {
            errors.selectedDocumentType = 'Document type is required.';
          }

          if(!selectedDocumentNo)
          {
            errors.selectedDocumentNo = 'Document no is required.';
          }

          if(!documentFile)
          {
            errors.documentFile = 'Document file is required.';
          }
          setValidationErrors(errors);

          if(Object.keys(errors).length > 0)
          {
            return;
          }

          const formData = new FormData();
          for (let i = 0; i < documentFile.length; i++) {
            formData.append('document_file[]', documentFile[i]);
          }

          formData.append('document_type',selectedDocumentType);
          formData.append('document_no',selectedDocumentNo);
         // formData.append('document_file',JSON.stringify(documentFile));
          formData.append('client_id',clientId);
          formData.append('created_by',emp_id);
          try 
          {
              const response = await dashboardApi.post('uplode-client-document', formData);
                if(response.data.status === 200)
                {
                  Swal.fire({
                  icon: 'success',
                  text: response.data.message,
                  });
                  setSelectedDocument('');
                  setDocumentFile('');
                  setSelectedDocumentType('');
                  getClientUplodedDocument();
                }
                else
                {
                  Swal.fire({
                  icon: 'error',
                  text: response.data.message || 'An error occurred',
                  });
                }
          }catch (error){
            Swal.fire({
                icon: 'error',
                text: 'An error occurred while creating the product',
            });
          }
      }

    const emptyFollowupFields = ()=>
    {
        setFollCallStatus('');
        setFollStatus('');
        setNxtFollowupDate('');
        setFollowupRemarks('');
        setSelectedPackage('');
        setSelectedProduct('');
        setSelectedService('');
        setSelectedCategory('');
        setSelectedCity('');
       
    }

    const submitFollowupForm = async () =>
    {
      const errors = {};
      if (!selectedCategory)
      {
        errors.selectedCategory = 'Category is required';
      }
      if (!followupRemarks)
      {
        errors.followupRemarks = 'Remarks are required';
      }
    
      if ([5, 6, 7, 9].includes(parseInt(selectedFollowStatus))) {
        if (!selectedPackage) {
          errors.selectedPackage = 'Package is required';
        }
      }
    
      if (!nxtFollowupDate)
      {
        errors.nxtFollowupDate = 'Next Follow-up Date is required';
      }
    
      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }
    
      setValidationErrors({});
    
      const formData = new FormData();
      formData.append('disposition', selectedFollowCallStatus);
      formData.append('followup_status', selectedFollowStatus);
      formData.append('next_followup_date', nxtFolDate);
      formData.append('remarks', followupRemarks);
      formData.append('refer_package_id', selectedPackage);
      formData.append('client_id', clientId);
      formData.append('created_by', emp_id);
      formData.append('client_type', 1);
      formData.append('product_id', selectedProduct);
      formData.append('service_id', selectedService);
      formData.append('category_id', selectedCategory);
      formData.append('city_id', selectedCity);
    
      try {
        const response = await dashboardApi.post('add-client-followup', formData);
        if (response.data.status === 200)
        {
          Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
          closeFollowupControl();
          checkFollowupDetails();
          emptyFollowupFields();
        } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          text: 'An error occurred while creating the follow-up',
        });
      }
    };
    


    const submitCreatePackageForm = async() =>
    {
      const errors = {};
      if (Object.keys(errors).length > 0)
      {
        return; 
      }
      const formData = new FormData();
      formData.append('disposition',selectedFollowCallStatus);
      formData.append('followup_status',selectedFollowStatus);
      formData.append('next_followup_date',nxtFolDate);
      formData.append('remarks',followupRemarks);
      formData.append('refer_package_id',selectedPackage);
      formData.append('client_id',clientId);
      formData.append('created_by',emp_id);
      formData.append('client_type',1);
      formData.append('product_id',selectedProduct);
      formData.append('service_id',selectedService);
      formData.append('category_id',selectedCategory);
      formData.append('city_id',selectedCity);
       
      try 
      {
          const response = await dashboardApi.post('create-client-package', formData);
            if(response.data.status === 200)
            {
                Swal.fire({
                icon: 'success',
                text: response.data.message,
                });
              closeFollowupControl();
              checkFollowupDetails();
            }
            else
            {
              Swal.fire({
              icon: 'error',
              text: response.data.message || 'An error occurred',
              });
            }
      }catch (error){
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
    }
 
    const submitPackageForm = async ()=>{
      
      const response = await dashboardApi.get('buy-new-package',{
        params:{
          client_id:clientId,
          package_id:selectedPackage,
          depositAmount:deposit_amount,
          created_by:emp_id
        }
      });
      if (response.data.status === 200)
      {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
          });
        
      } 
    }

    const getWalletDetails = async () =>
    {
        try {
            const response = await dashboardApi.get(`get-wallet-details/${clientId}`);
            if(response.data.status === 200)
            {
              setWalletDetails(response.data.data);
            } 
          }
          catch(error)
          {
            console.error('Error fetching countries:', error);
          }
    }
  
  
    const getCityDetails= async()=>
    {
      try {
          const response = await dashboardApi.get(`get-city-details`);
          if (response.data.status === 200)
          {
            setCityList(response.data.data);
          } 
          else
          {
           console.log('Data not found');
          }
        } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
        }
    }

    const getPrePackageDetails = async() =>{
      const response = await dashboardApi.get(`get-lmart-pre-packages-list`);
      if (response.data.status === 200)
      {
        setLmartPrePackageDetails(response.data.data);
      } 
    }

  
  useEffect(()=>{
    checkClientDetails();
    getCityDetails();
    getProduct();
    //getWalletDetails();
    getGroupList();
    getPackageCategory();
  },[])

  const getProduct = async () => 
  {
    try{
      const response = await dashboardApi.get(`sales-product-list/active`);
      if(response.data.status === 200)
      {
        setProduct(response.data.data);
      }
      else
      {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error)
    {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Ensure gstNumber is a string
    const gstNumberStr = gstNumber || '';

    if (gstOption === 'Yes') {
      if (gstNumberStr.length === 0) {
        setValidationErrors({ ...validationErrors, gstNumber: 'GST Number is required.' });
      } else if (gstNumberStr.length !== 3) {
        setValidationErrors({ ...validationErrors, gstNumber: 'Enter exactly 3 characters.' });
      } else {
        setValidationErrors({ ...validationErrors, gstNumber: '' });
      }
    } else {
      setValidationErrors({ ...validationErrors, gstNumber: '' });
    }
  }, [gstOption, gstNumber]);

  

  const handleGstNumberChange = (e) => {
    const value = e.target.value.toUpperCase();
    if (value.length <= 3) {
      setGstNumber(value);
    }
  };

    useEffect(()=>{
      if(selectedProduct)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-service-list-by-product-id/${selectedProduct}`);
            if (response.data.status === 200) {
              setService(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedProduct]);

    useEffect(()=>{
      if(selectedProduct && selectedCity && selectedService)
      {
        const fetchService = async () => {
          try {
            const response = await dashboardApi.get(`get-pre-package-list/${selectedProduct}/${selectedCity}/${selectedService}`);
            if (response.data.status === 200) {
              setPackageList(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchService();
      }
    },[selectedCity,selectedCity,selectedService]);

    useEffect(()=>{
      if(selectedPackage && selectedFollowStatus==19)
      {
        const fetchPrePackageDetails = async () => {
          try {
            const response = await dashboardApi.get(`get-sale-package-details/${selectedPackage}`);
            if (response.data.status === 200) {
              setSalePackageList(response.data.data);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchPrePackageDetails();
      }
    },[selectedPackage,selectedFollowStatus]);

    useEffect(()=>{
      if(selectedPackage && selectedFollowStatus==19)
      {
        const fetchTaxRegDetails = async () => {
          try {
            const response = await dashboardApi.get(`get-tax-and-reg-details/${selectedProduct}`);
            if (response.data.status === 200) {
              setRegistrationAmount(response.data.data.reg_amount);
              setGstPercent(response.data.data.gst_percent);
            } else {
              console.error('Error fetching countries:', response.data.message);
            }
            setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
        }
        fetchTaxRegDetails();
      }
    },[selectedPackage,selectedFollowStatus]);


    
  
      useEffect(()=>{
          if(selectedService)
          {
          const fetchCategory = async () => {
            try {
              const response = await dashboardApi.get(`get-category-list-by-service-id/${selectedService}`);
              if (response.data.status === 200) {
                  setCategory(response.data.data);
              } else {
                  console.error('Error fetching countries:', response.data.message);
              }
              setLoading(false);
              } catch (error) {
              console.error('Error fetching countries:', error);
              setLoading(false);
              }
          }
          fetchCategory();
          }
      },[selectedService]);

    useEffect(()=>{
      if(selectedFollowCallStatus)
      {
        const getFollowupStatus = async () => {
          try {
          const response = await dashboardApi.get(`get-followup-list/${selectedFollowCallStatus}`);
          if (response.data.status === 200) {
            setFollowup(response.data.data);
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
          console.error('Error fetching countries:', error);
          setLoading(false);
          }
      }
      getFollowupStatus();
      }
    },[selectedFollowCallStatus]);
 

  useEffect(()=>{
    if(selectedFollowStatus)
    {
      const getNextFollowupDate = async () => {
          try {
          const response = await dashboardApi.get(`get-next-followup-date/${selectedFollowStatus}`);
          if (response.data.status === 200)
          {
            setNextFollowupDate('');
            setNextDays(response.data.data);
            
          } else {
              console.error('Error fetching countries:', response.data.message);
          }
          setLoading(false);
          } catch (error) {
            console.error('Error fetching countries:', error);
            setLoading(false);
          }
      }
      getNextFollowupDate();
    }
  },[selectedFollowStatus]);

  const handleFormSubmit = async(e) =>
  {

      e.preventDefault();
      const errors = {};
      if (Object.keys(errors).length > 0)
      {
        return; 
      }
      const formDataClient = new FormData();
      
      //client basic info
      formDataClient.append('clientName',clientName);
      formDataClient.append('clientEmail',clientEmail);
      formDataClient.append('clientMobile',clientMobile);
      formDataClient.append('clientCity',clientCity);
      formDataClient.append('clientState',clientState);
      formDataClient.append('clientZipcode',clientZipcode);
      formDataClient.append('clientAddress',clientAddress);
      formDataClient.append('clientPanCardNo',clientPanCardNo);
      formDataClient.append('created_by',emp_id);
      formDataClient.append('guestClientId',clientId);

      //company details
      formDataClient.append('businessName',businessName);
      formDataClient.append('businessEmail',businessEmail);
      formDataClient.append('businessMobile',businessMobile);
      formDataClient.append('companyCity',companyCity);
      formDataClient.append('companyState',companyState);
      formDataClient.append('companyAddress',companyAddress);
      formDataClient.append('companyPanCardNo',companyPanCardNo);
      formDataClient.append('companyZipcode',companyZipcode);
      formDataClient.append('clientGroup',selectedGroup);
      formDataClient.append('orgTypeId',orgTypeId);
      formDataClient.append('clientProduct',selectedProduct);
      formDataClient.append('clientService',selectedService);

      try {
        const response = await dashboardApi.post('add-mature-client-details', formDataClient);
        console.log(response);
        if(response.data.status === 200)
        {
            Swal.fire({
                icon: 'success',
                text: response.data.message,
            });
            navigate("/verified-client-details");
        }
        else if (response.data.status === 400) {
            //setValidationErrors(response.data.messages);
        } 
        else
        {
            Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
            });
        }
      } catch (error) {
        Swal.fire({
            icon: 'error',
            text: 'An error occurred while creating the product',
        });
      } 
  }
  
  const Tab = styled.button`
  font-size: 16px;
  padding: 10px 10px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid blue;
    opacity: 1;
  `}
`;
const ButtonGroup = styled.div`
  display: flex;
`;
const types = ["Followup","Client Verify","Services"];
const [active, setActive] = useState(types[0]);
const [stateList,setStateList] = useState('');
const [cityDetails,setCityDetails] = useState([]);


// Start View Package Offer Details 
    
  const [checkedOffers, setCheckedOffers] = useState(null);
  const [selectedOfferTypes, setSelectedOfferTypes] = useState({});

  const [selectedPercent, setSelectedPercent] = useState({});
  const [discountPercent, setDiscountPercent] = useState('');
  const [discountType, setDiscountType] = useState('');
  const [applyDiscountPercent, setApplyDiscountPercent] = useState('');
  const [afterDiscountLead, setAfterDiscountLead] = useState('');
  const [paybleAmountAfterDiscount, setPaybleAmountAfterDicount] = useState('');
  const [partialPayment, setPartialAmount] = useState('');
  const [selectedPackageCity, setSelectedPackageCity] = useState([]);
  const citiesValue = selectedPackageCity.map(e => e.value).join(',');
  const [regAmount, setRegAmount] = useState(0);
  const [packageType, setPackageType] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const [balanceAmount, setBalanceAmount] = useState('');
  const [dueLeads, setDueLeads] = useState('');
  const [taxAmount, setTaxAmount] = useState('');
  const [serviceCharge, setServiceCharge] = useState(0);
  const [selectedComp, setSelectedComp] = useState();
  const [clientStatus, setClientStatus] = useState('');
  const [lastFollowupStatus, setLastFollowupStatus] = useState(''); //1= hide and 2=show
  const [lastFollowupBy, setLastFolloeupBy] = useState('');
  
  const [payblePackageAmount, setPayblePackageAmount] = useState('');

  useEffect(() => {
    const calculatePayableAmount = () => {
      // Calculate the payable amount after discount and tax
      const discountedPrice = packagedetails.package_price - (packagedetails.package_price * discountPercent) / 100;
      const payableAmount = Math.round(discountedPrice + discountedPrice * 0.18);
      setPaybleAmountAfterDicount(payableAmount);
    };

    // Call the calculation function
    calculatePayableAmount();
  }, [packagedetails.package_price, discountPercent]);

    // Calculate the payable amount when dependencies change
    useEffect(() => {
      const calculatedAmount =
        discountPercent && selectedOfferTypes && discountType === 1
          ? Math.round(Number(regAmount) + Number(paybleAmountAfterDiscount))
          : Math.round(Number(regAmount) + Number(packagedetails.price));
  
      setPayblePackageAmount(calculatedAmount);
    }, [discountPercent, selectedOfferTypes, discountType, regAmount, paybleAmountAfterDiscount, packagedetails.price]);
  
    useEffect(() => {
      // Update the afterDiscountLead when packagedetails or discountPercent changes
      const calculatedLead = Math.round(
        Number(packagedetails.total_lead) +
        Number((packagedetails.total_lead * discountPercent) / 100)
      );
      setAfterDiscountLead(calculatedLead);
    }, [packagedetails, discountPercent]);

  const handleOfferCheck = (offerId) => {
    
    setCheckedOffers(offerId);
    setSelectedOfferTypes(prevState => ({
      ...prevState,
      [offerId]: prevState[offerId] || ''
    }));
    setSelectedPercent(prevState => ({
      ...prevState,
      [offerId]: ''
    }));
    setSelectedPercent('');
    setDiscountPercent('');
    setDiscountType('');
  };

    
  const handleOfferTypeChange = (id, type) => {
    setSelectedOfferTypes((prev) => ({
      ...prev,
      [id]: type,
    }));
    setSelectedPercent((prev) => ({
      ...prev,
      [id]: '',
    }));
  };

  const handlePercentChange = (id, value) => {
    setSelectedPercent((prev) => ({
      ...prev,
      [id]: value,
    }));
    setDiscountPercent(value);
  };

  
  const handlePartialPaymentChange = (value) => {
    setPartialAmount(value);

    if (value == '') {
      setPartialAmount(0);
    
    }
  };

  useEffect(() => {
    if (selectedComp) {
      const getCompanyPackageDetails = async () => {
        try {
          const response = await dashboardApi.get(`check-company-package-info`, {
            params: {
              client_id: clientId,
              comp_id: 'New',
              pre_package_id: selectedSalePackageId,
            }
          });

          if (response.data.status === 200) {
            setPackageType(response.data.packageType);
            setCompanyType(response.data.companyType);
            setRegAmount(response.data.regAmount);
            setServiceCharge(response.data.serviceCharge);
          }
          else {
            console.error('Error fetching countries:', response.data.message);
          }
          //setLoading(false);
        } catch (error) {
          console.error('Error fetching countries:', error);
          //setLoading(false);
        }
      }
      getCompanyPackageDetails();
    }
  },[selectedComp]);


  useEffect(() => {
    handlePartialPaymentChange(0);
  }, []);
  const checkTaxAmount = async (totalAmount, totalLead, isPartial, regAmount) => {
    if(isPartial=='')
    {
      setPartialAmount(0);
    }
    
    const errors = {};
    let paidAmountWillBe = '';
    //alert(partialPayment);
    if(isPartial=='')
    {
      setPartialAmount(0);
    }
    const depositAmountNum = parseFloat(depositAmount);
    // Validate partial payment
    if (partialPayment === '') {
      errors.partialPayment = 'Partial payment check is required';
    }
    // Validate deposit amount
    if (isNaN(depositAmountNum) || depositAmountNum <= 0) {
      errors.depositAmount = 'Deposit amount must be a positive number';
    }

    if (partialPayment === 0) {
      setDueLeads(0);
    }
    else {
      let paidAmountPercent = (depositAmountNum / totalAmount) * 100; // Calculate the percentage
      // alert(paidAmountPercent);
      let totalDueLead; // Define the variable outside the conditions

      if (paidAmountPercent >= 50 && paidAmountPercent < 60) {
        totalDueLead = Math.round(totalLead * 33 / 100);
      } else if (paidAmountPercent >= 60 && paidAmountPercent < 70) {
        totalDueLead = Math.round(totalLead * 40 / 100);
      } else if (paidAmountPercent >= 70 && paidAmountPercent < 80) {
        totalDueLead = Math.round(totalLead * 45 / 100);
      } else if (paidAmountPercent >= 80 && paidAmountPercent < 90) {
        totalDueLead = Math.round(totalLead * 65 / 100);
      } else if (paidAmountPercent >= 90 && paidAmountPercent < 100) {
        totalDueLead = Math.round(totalLead * 80 / 100);
      } else if (paidAmountPercent == 100) {
        totalDueLead = 0;
      }

      setDueLeads(totalDueLead); // Set the state
    }

    // Calculate paid amount based on partial payment
    if(isPartial === 0)
      {
      paidAmountWillBe = totalAmount;
    } else {
      paidAmountWillBe = Math.round(totalAmount / 2);
    }

    // Check if deposit amount is valid
    if (depositAmountNum >= paidAmountWillBe) {
      const balanceAmount = Math.round(totalAmount - depositAmountNum);
      const taxAmount = Math.round(depositAmountNum - (depositAmountNum / 1.18));

      // Ensure balanceAmount and taxAmount are non-negative
      if (balanceAmount < 0) {
        errors.depositAmount = 'Deposit amount cannot exceed the total amount';
        setBalanceAmount('');
        setTaxAmount('');
      } else {
        setBalanceAmount(balanceAmount);
        setTaxAmount(taxAmount);
      }
    } else {
      errors.depositAmount = 'Deposit amount must be more than half and less than or equal to the package amount';
      setBalanceAmount('');
      setTaxAmount('');
    }
   

    setValidationErrors(errors);

    // Reset deposit amount if there are errors
    if (Object.keys(errors).length > 0) {
      setDepositAmount('');
      return; // Exit early if there are errors
    }
  };

//End View Package Details

styled.td`
  padding: .1rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
  margin-bottom: .1rem;
  `;
  useEffect(()=>{
    checkFollowupDetails();
    getOrganizationType();
    getStateList();
    getPrePackageDetails();
    getClientUplodedDocument();
    checkLastFollowupDetails();
  },[])


  const checkFollowupDetails = async () =>
  {
    try {
        //const response = await dashboardApi.get(`check-client-followup-details/${clientId}`);
        const response = await dashboardApi.get(`check-client-followup-details/${clientId}`, {
          params: {
            emp_id: emp_id,
            clientType:1
          },
        });
        if (response.data.status === 200)
        {
          setClientFollowup(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }


  const checkLastFollowupDetails = async () =>
  {
    try {
      const response = await dashboardApi.get(`check-last-guest-followup-details`,{
        params: {
          emp_id: emp_id,
          client_id: clientId,
        }
      });
      if (response.data.status === 200)
      {
        setLastFollowupStatus(response.data.followupStatus);
        setLastFolloeupBy(response.data.lastFolloeupBy);
        setClientStatus(response.data.clientStatus);
      }
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }



  useEffect(() => {
   // console.log("Dependencies:", { orgTypeId, selectedProduct, selectedService });
  
    if (orgTypeId && selectedProduct && selectedService)
    {
      const getDocumentType = async () => {
        setLoading(true); // Optionally set loading to true here
        try {
          const response = await dashboardApi.get(`get-document-type/${orgTypeId}/${selectedProduct}/${selectedService}`);
          
          if (response.data.status === 200) {
            setDocumentType(response.data.data);
          } else {
            console.error('Error: Response status not 200', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching document type:', error);
        } finally {
          setLoading(false); // Make sure loading is reset even in case of error
        }
      };
  
      getDocumentType();
    }
  }, [orgTypeId, selectedProduct, selectedService]);

  const getOrganizationType = async () =>
  {
    try {
        const response = await dashboardApi.get(`get-organization-type`);
        if(response.data.status === 200)
        {
          setOrgType(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }

  const getStateList = async () =>
  {
    try {
        const response = await dashboardApi.get(`get-state-list`);
        if(response.data.status === 200)
        {
          setStateList(response.data.data);
        } 
      }
      catch(error)
      {
        console.error('Error fetching countries:', error);
        setLoading(false);
      }
  }



  useEffect(() => {
    // Check if either clientState or companyState is set
    if (clientState || companyState) {
      let citySearch = ''; // Use 'let' for reassigning values
  
      // Set citySearch based on which state is selected
      if (clientState) {
        citySearch = clientState;
      } else {
        citySearch = companyState;
      }
  
      // Fetch city list based on the selected state
      const getCityList = async () => {
        try {
          const response = await dashboardApi.get(`get-city-by-state-name/${citySearch}`);
          if (response.data.status === 200) {
            setCityDetails(response.data.data);
          } else {
            Swal.fire({
              icon: 'error',
              text: response.data.message,
            });
          }
        } catch (error) {
          Swal.fire({
            icon: 'error',
            text: 'Failed to fetch city list. Please try again.',
          });
        }
      };
  
      getCityList();
    }
  }, [companyState, clientState]);
 

 


    const sentPurposal = async (package_id,package_price,paybale_price,discountType,discountPercent,regAmount,total_lead) => {
      setProposalId(package_id);
      setLoading(true);
      
      try {
        const response = await dashboardApi.get('sent-purposal-to-client', {
          params: {
            id: package_id,
            discountType:discountType,
            discountPercent:discountPercent,
            regAmount:regAmount,
            package_price:package_price,
            paybale_price:paybale_price,
            total_lead:total_lead,
            client_id: clientId,
            comp_id:selectedComp,
            emp_id: selectedComp,
            offer_id:checkedOffers,
            offer_category:selectedOfferTypes,
            offer_rate:selectedPercent,
            companyType:companyType,
            user_type: 'guest'
          }
        });
        if (response.data.status === 200) {
          setLoading(false);
          setpackageby(false);
          setProposalId('');
          Swal.fire({
            icon: 'success',
            text: response.data.message || 'An error occurred',
          });
        }
        else {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            text: response.data.message || 'An error occurred',
          });
        }
        //console.log(packagedetails);
      }
      catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      }
    }

    const closePackageModel=()=>{
      setpackageby(false);
      packageFormFieldEmpty();
    }

    const packageFormFieldEmpty = () => {
      setDepositAmount('');
      setCheckedOffers('');
      setApplyDiscountPercent('');
      setApplyDiscountPercent('');
      setPartialAmount('');
      setPaybleAmountAfterDicount('');
      setSelectedComp('');
      setDiscountPercent('');
      setDiscountType('');
      setPayblePackageAmount('');
      setDueLeads('');
      setTaxAmount('');
      setRegAmount('');
      setCompanyType('');
     
    }

  const [step, setStep] = useState(1);




  const handleNext = async () => {
    const errors = {};
  
    if (!clientName) {
      errors.clientName = 'Name is required';
    }
  
    if (!clientEmail) {
      errors.clientEmail = 'Email is required';
    }
  
    if (!selectedGroup) {
      errors.selectedGroup = 'Group is required';
    }
  
    if (!clientMobile) {
      errors.clientMobile = 'Mobile Number is required';
    }
    else if(clientMobile)
    {
      // Check if client mobile number is already in the system
      const response = await dashboardApi.get(`check-client-mobile-number-for-verified/${clientMobile}`);
      if (response.data === 1) {
        errors.clientMobile = `Mobile number already added in company details ( ${clientMobile} ).`;
      }
    }
  
    if (!clientCity) {
      errors.clientCity = 'City is required';
    }
  
    if (!clientState) {
      errors.clientState = 'State is required';
    }
  
    if (!clientZipcode) {
      errors.clientZipcode = 'Zipcode is required';
    }
  
    if (gstOption === "") {
      errors.gstOption = "Please select an option for GST.";
    }


  
    if (!clientAddress) {
      errors.clientAddress = 'Address is required';
    }
  
    if (!clientPanCardNo) {
      errors.clientPanCardNo = 'Pancard No is required';
    } else if (clientPanCardNo) {
      // Check if PAN Card is already in the system
      const response = await dashboardApi.get(`check-client-pancard-number/${clientPanCardNo}/${clientId}`);
      if (response.data === 1) {
        errors.clientPanCardNo = `Pancard already added in our system ( ${clientPanCardNo} ).`;
      }
    }
  
    // Company Details Validation
    if (!businessName) {
      errors.businessName = 'Business Name is required';
    }
  
    if (!businessEmail) {
      errors.businessEmail = 'Business email is required';
    }
  
    if(!businessMobile)
    {
      errors.businessMobile = 'Business mobile is required';
    }
    else if(businessMobile)
    {
      // Check if company mobile number is already in the system
      const response = await dashboardApi.get(`check-client-mobile-number-for-verified/${businessMobile}`);
      if (response.data === 1)
      {
        errors.businessMobile = `Mobile number already added in company details ( ${businessMobile} ).`;
      }
    }
  
    if(!companyCity)
    {
      errors.companyCity = 'Company city is required';
    }
  
    if (!companyState) {
      errors.companyState = 'Company state is required';
    }
  
    if (!companyAddress) {
      errors.companyAddress = 'Company address is required';
    }
  
    if(!companyPanCardNo)
    {
      errors.companyPanCardNo = 'Company PAN card is required';
    }
    else if(companyPanCardNo)
    {
      // Check if PAN Card is already in the system
      const response = await dashboardApi.get(`check-client-pancard-number/${companyPanCardNo}/${clientId}`);
      if(response.data === 1)
      {
        errors.companyPanCardNo = `Pancard already added in our system ( ${companyPanCardNo} ).`;
      }
    }

    if(gstOption=='Yes')
    {
      if(!gstNumber)
      {
        errors.gstNumber = 'GST Number is required if you have selected Yes.';
      }else
      {
        setCompanyGstNumber(gstStateCode+companyPanCardNo+gstNumber);
       
      }
    }

    if (!companyZipcode) {
      errors.companyZipcode = 'Company zipcode is required';
    }
  
    // Organization Type Validation
    if (!orgTypeId) {
      errors.orgTypeId = 'Org Type is required';
    }
  
    // Product and Service Validation
    if (!selectedProduct) {
      errors.selectedProduct = 'Product is required';
    }
  
    if (!selectedService) {
      errors.selectedService = 'Service is required';
    }
  
    // Set validation errors
    setValidationErrors(errors);
  
    // Proceed to the next step only if no errors
    if (Object.keys(errors).length === 0) {
      setStep(step + 1);
    }
  };
  
  const handlePrevious = () => {
    setStep(step - 1);
  };

  const followupOpenControl=()=>{
    setFollowupModal(true);
  }
  
  const closeFollowupControl = ()=>
  {
    setFollowupModal(false);
  }

  const closeModalOpenControl = ()=>
  {
    setAddModal(false);
  }

  const checkClientDetails = async () =>
  {
    setIsLoading(true);
    try {
        const response = await dashboardApi.get(`check-client-details/${clientId}`);
        if(response.data.status === 200)
        {
          setIsLoading(false);
          setClientName(response.data.data.name);
          setBusinessName(response.data.data.business_name)
          setClientEmail(response.data.data.email_id);
          setClientMobile(response.data.data.mobile_no)
          setClientCity(response.data.data.city_name);
          setClientState(response.data.data.state);
          setZipCode(response.data.data.zipcode);
          setClientAddress(response.data.data.address);
          setSelectedGroup(response.data.data.group_id);
          setSelectedProduct(response.data.data.product_id);
          //setSelectedService(response.data.data.service_id);
          
        } 
      }
      catch(error)
      {
        setIsLoading(false);
        console.error('Error fetching countries:', error);
      }
  }

    const Tab1 = styled.button`
    font-size: 16px;
    padding: 10px 10px;
    margin-right:10px;
    cursor: pointer;
    opacity: 0.6;
    color:black;
    border: 0;
    outline: 0;
    ${({ active }) => active && `border-bottom: 4px solid #0e3a6a; opacity: 1;`}`;

    const ButtonGroup1 = styled.div`display: flex;`;
    const topMenuBar1 = ["Lmart Packages","Zoopgo Packages","Others Packages","Website Packages"];
    const [activeMenu1, setActive1] = useState(topMenuBar1[0]);
    useEffect(() => {
      if (
        checkedOffers &&
        selectedPercent[checkedOffers] &&
        selectedOfferTypes[checkedOffers]
      ) {
        const getOfferDetails = async () => {
          const response = await dashboardApi.get(`get-package-offer-details`, {
            params: {
              checkedOffers,
              selectedOfferTypes: selectedOfferTypes[checkedOffers],
              selectedPercent: selectedPercent[checkedOffers],
            },
          });
  
          if (response.data.status === 200) {
            setDiscountType(response.data.type);
            setDiscountPercent(response.data.percent);
          }
        };
        getOfferDetails();
      }
    }, [checkedOffers, selectedOfferTypes, selectedPercent]);

  return (
    
    <DashboardLayout>
      <LoadingModal isLoading={isLoading} />
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <ClientProfileHeader></ClientProfileHeader>
                <div className="row">
                  <ButtonGroup>
                  {types.map((type) => (
                    <Tab
                      key={type}
                      active={active === type}
                      onClick={() => setActive(type)}
                    >
                      {type}
                    </Tab>
                  ))}
                </ButtonGroup>
                </div>
              </div>

                {active ==='Followup' &&
                  <div className='card-body' id="followupDiv" style={{overflowY: "hidden"}}>
                      <table className="table table-bordered table-hovered">
                        <tr>
                          <td colspan="12">
                          {lastFollowupStatus == '1'&&
                            <Link className='btn btn-info btn-sm' style={{float:'right'}} onClick={followupOpenControl}>
                              +
                            </Link>
                          }
                          </td>
                        </tr>
                        <tr style={{fontWeight:'700'}}>
                          <td>S.No</td>
                          <td>Client Type</td>
                          <td>City Name</td>
                          <td>Refer Package Name</td>
                          <td>Package Price</td>
                          <td>Disposition</td>
                          <td>Followup Info</td>
                          <td>Followup Status</td>
                          <td>Remark</td>
                          <td>Followup Date</td>
                          <td>Created By</td>
                          <td>Created Date</td>
                        </tr>

                        {clientFollowup && clientFollowup.map((cd,index)=>(
                          <tr>
                              <td>{index + 1}</td>
                              <td>{cd.client_type  === 1 ?'Guest':'Mature'}</td>
                              <td>{cd.city_name}</td>
                              <td>{cd.package_name}</td>
                              <td>{cd.package_price}</td>
                              <td>{cd.disposition}</td>
                              <td>{cd.product_name}<br/>{cd.service_name}<br/>{cd.category_name}</td>
                              <td>{cd.activity_name}</td>
                              <td>{cd.remark}</td>
                              <td>{cd.next_followup_date}</td>
                              <td>{cd.created_by}</td>
                              <td>{cd.created_date}</td>
                          </tr>
                          ))
                        }
                      </table>
                  </div>
                }

                {active ==='Client Verify' &&
                <>
                  <div>
                  <form onSubmit={handleFormSubmit}>
                      <ProgressBar now={(step / 2) * 100} />
                      {step === 1 && (
                        <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                        
                        <div className="row">
                          {/* <div className="col-sm-12"><h4>Client Basic Info</h4></div> */}
                          <div className="col-sm-12 " style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                              <strong style={{ margin: "0 10px" }}>Client Basic Details</strong>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                          </div>
                          <div className="col-sm-4">
                            <label for="client_name">Client Name</label>
                             <input type="text" value={clientName} className="form-control" onChange={(e) =>setClientName(e.target.value)}/>
                             {validationErrors.clientName && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientName}</div>
                             )}
                          </div>

                          

                          <div className="col-sm-4">
                            <label>Email</label>
                             <input type="text" className="form-control" value={clientEmail} onChange={(e) =>setClientEmail(e.target.value)} />
                             {validationErrors.clientEmail && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientEmail}</div>
                             )}
                          </div>
                          <div className="col-sm-4">
                            <label>Mobile No</label>
                             <input type="text" className="form-control" value={clientMobile} onChange={(e) =>setClientMobile(e.target.value)} readOnly/>
                             {validationErrors.clientMobile && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientMobile}</div>
                             )}
                          </div>
                          
                         

                          <div className="col-sm-4">
                            <label>State</label>
                            <select
                              className="form-control"
                              value={clientState}
                              onChange={handleStateChange}
                              //onChange={(e) =>setClientState(e.target.value)}
                            >
                              <option value="">Select State</option>
                              {stateList && stateList.map((sl) => (
                                <option key={sl.id} value={sl.id}>
                                  {sl.state_name}
                                </option>
                              ))}
                            </select>

                            {validationErrors.clientState && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientState}</div>
                             )}
                          </div>

                          <div className="col-sm-4">
                            <label>City</label>
                              <select
                                className="form-control"
                                value={clientCity} 
                                onChange={(e) =>setClientCity(e.target.value)}
                              >
                                <option value="">Select City</option>
                                {cityDetails && cityDetails.map((cd) => (
                                  <option key={cd.id} value={cd.id}>
                                    {cd.city_name}
                                  </option>
                                ))}
                              </select>
                             
                              {validationErrors.clientCity && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientCity}</div>
                             )}
                          </div>

                          <div className="col-sm-4">
                            <label>Zipcode</label>
                            <input
                              type="text"
                              id="zipCode"
                              value={clientZipcode}
                              onChange={handleChange}
                              placeholder="Enter ZIP code"
                              className="form-control"
                              maxLength="6" 
                            />
                            {validationErrors.clientZipcode && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.clientZipcode}
                              </div>
                            )}
                          </div>
    
                          <div className="col-sm-4">
                            <label>Address</label>
                             <input type="text" className="form-control" value={clientAddress} onChange={(e) =>setClientAddress(e.target.value)}/>
                             {validationErrors.clientAddress && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                             )}
                          </div>
    
                          

                          <div className="col-sm-4">
                            <label>Pan Card Number</label>
                            <input 
                              type="text" 
                              className="form-control" 
                              value={clientPanCardNo} 
                              onChange={(e) => {
                                const value = e.target.value.toUpperCase();
                                setPanCardNo(value);
                                // Simple regex to check PAN card format
                                const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                if (value.length !== 10 || !panCardPattern.test(value)) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    clientPanCardNo: "Please enter a valid 10-character PAN card number (e.g., ABCDE1234F)"
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    clientPanCardNo: ""
                                  }));
                                }
                              }}
                              maxLength="10" // This limits the input to 10 characters
                              onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                            />
                            {validationErrors.clientPanCardNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.clientPanCardNo}
                              </div>
                            )}
                          </div>


                          

                            <div className="col-sm-4 ">
                              <label>Do you have GST No?</label>
                              <div>
                              <span className='px-3' >
                                <input
                                style={{transform:"scale(1.5)"}}
                                  type="radio"
                                  id="yes"
                                  name="gst"
                                  value="Yes"
                                  checked={gstOption === "Yes"}
                                  onChange={(e) => setGstOption(e.target.value)}
                                />
                                <label className='pl-2' htmlFor="yes"> Yes</label>
                              </span>
                              <span  className='px-3' >
                                <input
                                style={{transform:"scale(1.5)"}}
                                  type="radio"
                                  id="no"
                                  name="gst"
                                  value="No"
                                  checked={gstOption === "No"}
                                  onChange={(e) => setGstOption(e.target.value)}
                                />
                                <label className='pl-2' htmlFor="no"> No</label>
                              </span>
                              </div>
                              {validationErrors.gstOption && (
                                <div className="text-danger" style={{ fontSize: "15px" }}>
                                  {validationErrors.gstOption}
                                </div>
                              )}
                            </div>

                            <div className=" p-2  my-2 col-12 px-2 ">
                              <label className='d-flex align-items-center p-2'>
                                <input 
                                style={{transform:"scale(1.5)"}}
                                  type="checkbox"
                                  checked={sameAsClientInfo}
                                  onChange={handleSameAsClientInfo}
                                />
                                <span className='pl-2 text-success'>Same as 'Client Basic Details,' but for an individual client.</span>
                               
                              </label>
                            </div>
                          </div>
                          {gstOption &&
                          <div className="row">
                            <div className="col-sm-12 fw-bold" style={{ display: "flex", alignItems: "center" }}>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                              <strong style={{ margin: "0 10px" }}>Company Details</strong>
                              <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                            </div>

                            <div className="col-sm-4">
                              <label>Organization Type</label>
                              <select 
                                className="form-control" 
                                value={orgTypeId} 
                                onChange={(e) => setOrgTypeId(e.target.value)}
                              >
                                <option key="3" value="">Select Organization Type</option>
                                
                                {sameAsClientInfo ? (
                                  <option value="3">Individual</option>
                                ) : (
                                  orgType && orgType.map((orgnaType) => (
                                    <option key={orgnaType.id} value={orgnaType.id}>
                                      {orgnaType.org_type}
                                    </option>
                                  ))
                                )}
                              </select>

                              {validationErrors.orgTypeId && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.orgTypeId}</div>
                              )}
                            </div>

                              <div className="col-sm-4">
                                <label>Salect Product</label>
                                <select className="form-control" value={selectedProduct}
                                      onChange={(e) => setSelectedProduct(e.target.value)}>
                                      <option value=''>Select Product</option>
                                      {product.map((pro) => (
                                      <option key={pro.id} value={pro.id}>
                                      {pro.product_name}
                                      </option>
                                      ))}
                                </select>
                                {validationErrors.selectedProduct && (
                                  <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedProduct}</div>
                                )}
                              </div>

                              {selectedProduct &&(
                                <div className="col-sm-4">
                                    <label>Select Service</label>
                                    <select className="form-control"
                                            value={selectedService}
                                            onChange={(e) => setSelectedService(e.target.value)}
                                            >
                                            <option value=''>Select Service</option>
                                            {service.map((ser) => (
                                            <option key={ser.id} value={ser.id}>
                                            {ser.service_name}
                                            </option>
                                            ))}
                                    </select>
                                    {validationErrors.selectedService && (
                                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedService}</div>
                                    )}
                                </div>
                              )}
                       

                          <div className="col-sm-4">
                            <label>Business Name</label>
                            <input type="text" className="form-control"  
                            readOnly={sameAsClientInfo === true}
                            style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                            value={businessName} 
                            onChange={(e) =>setBusinessName(e.target.value)} />
                            {validationErrors.businessName && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessName}</div>
                             )}
                          </div>

                          <div className="col-sm-4">
                            <label>Business Email</label>
                             <input type="text" className="form-control" 
                             value={businessEmail} 
                             readOnly={sameAsClientInfo === true}
                             style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                             onChange={(e) =>setBusinessEmail(e.target.value)} />
                             {validationErrors.businessEmail && (
                               <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.businessEmail}</div>
                             )}
                          </div>
                          

                          <div className="col-sm-4">
                            <label>Business Mobile No</label>
                            <input
                              type="text"
                              className="form-control"
                              readOnly={sameAsClientInfo === true}
                              style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                              value={businessMobile}
                              onChange={(e) => {
                                const value = e.target.value;
                               
                                if (/^\d*$/.test(value)) {
                                  setBusinessMobile(value);
                                }
                               
                                if (value.length !== 10) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    businessMobile: "Mobile number must be exactly 10 digits",
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    businessMobile: "",
                                  }));
                                }
                              }}
                              maxLength="10" // Limits the input to 10 characters
                            />
                            {validationErrors.businessMobile && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.businessMobile}
                              </div>
                            )}
                          </div>

                          

                          <div className="col-sm-4">
                            <label>Company Pan Card No</label>
                            <input 
                              type="text" 
                              className="form-control" 
                              value={companyPanCardNo}
                              readOnly={sameAsClientInfo === true}
                              style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }} 
                              onChange={(e) => {
                                const value = e.target.value.toUpperCase();
                                setCompanyPanCardNo(value);
                                // PAN card validation pattern: 5 letters, 4 digits, 1 letter
                                const panCardPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
                                if (value.length !== 10 || !panCardPattern.test(value)) {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    companyPanCardNo: "Please enter a valid 10-character PAN card number (e.g., ABCDE1234F)"
                                  }));
                                } else {
                                  setValidationErrors((prevErrors) => ({
                                    ...prevErrors,
                                    companyPanCardNo: ""
                                  }));
                                }
                              }}
                              maxLength="10" 
                              onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
                            />
                            {validationErrors.companyPanCardNo && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>
                                {validationErrors.companyPanCardNo}
                              </div>
                            )}
                          </div>
                            
                        
                          <div className="col-sm-4">
                              <label>Company GST State</label>
                              <select
                                className="form-control"
                                value={companyState}
                                //readOnly={sameAsClientInfo === true}
                                disabled={sameAsClientInfo === true}
                                style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                                onChange={handleStateChangeCompany}
                              >
                                <option value="">Select State</option>
                                {stateList && stateList.map((sl) => (
                                  <option key={sl.id} value={sl.id}>
                                    {sl.state_name}
                                  </option>
                                ))}
                              </select>

                              {validationErrors.companyState && (
                                <div className="text-danger" style={{ fontSize: "15px" }}>
                                  {validationErrors.companyState}
                                </div>
                              )}
                            </div>

                            <div className="col-sm-4">
                              <label>Company City</label>
                                <select
                                  className="form-control"
                                  value={companyCity}
                                  onChange={(e)=>{setCompanyCity(e.target.value)}}
                                  //readOnly={sameAsClientInfo === true}
                                  disabled={sameAsClientInfo === true}
                                  style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                                >
                                  <option value="">Select City</option>
                                  {cityDetails && cityDetails.map((cd) => (
                                    <option key={cd.id} value={cd.id}>
                                      {cd.city_name}
                                    </option>
                                  ))}
                                </select>
                              
                              {validationErrors.companyCity && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.companyCity}</div>
                              )}
                            </div>

                            <div className="col-sm-4">
                              <label>Company Zipcode</label>
                              <input
                                type="text"
                                className="form-control"
                                value={companyZipcode}
                                onChange={handleCompanyZipcode}
                                readOnly={sameAsClientInfo === true}
                                style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                                placeholder="Enter ZIP code"
                                maxLength="6"
                              />
                              {validationErrors.companyZipcode && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>
                                  {validationErrors.companyZipcode}
                                </div>
                              )}
                            </div>

                            <div className="col-sm-4">
                              <label>Company Address</label>
                              <input type="text" className="form-control" value={companyAddress} 
                              readOnly={sameAsClientInfo === true}
                              style={{ backgroundColor: sameAsClientInfo === true ? '#e9ecef' : 'white' }}
                              onChange={(e) =>setCompanyAddress(e.target.value)}/>
                              {validationErrors.clientAddress && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.clientAddress}</div>
                              )}
                            </div>

                           

                            {gstOption === "Yes" && (
                              <div className="col-sm-4">
                                <label>GST State Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={gstStateCode}
                                  readOnly
                                />

                                {validationErrors.gstStateCode && (
                                  <div className="text-danger" style={{ fontSize: "15px" }}>
                                    {validationErrors.gstStateCode}
                                  </div>
                                )}
                              </div>
                            )}
                          {gstOption =='Yes' &&
                          <div className="col-sm-4">
                            <label>GST Number</label>
                            <div style={{display:'flex'}}>
                             <input type="text" className="form-control" value={gstStateCode + companyPanCardNo} readOnly/>
                             
                             <input
                                type="text"
                                className="form-control"
                                value={gstNumber}
                                onChange={handleGstNumberChange}
                                placeholder='Enter last 3 digits of GST'
                                maxLength="3"
                               
                              />
                            </div>
                              {validationErrors.gstNumber && (
                                <div className="text-danger" style={{ fontSize: '15px' }}>
                                  {validationErrors.gstNumber}
                                </div>
                              )}
                          </div>
                        }

                        <div className="col-sm-4">
                            <label>Select Group</label>
                            <select className="form-control" value={selectedGroup} onChange={(e)=>{setSelectedGroup(e.target.value)}}>
                                <option value="">Select Group</option>
                                {groupList && groupList.map((group)=>(
                                  <option value={group.group_id}>{group.name}</option>
                                ))}
                            </select>
                            {validationErrors.selectedGroup && (
                              <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedGroup}</div>
                            )}
                          </div>

                            
                            </div>
                          }
                      </div>
                      )}

                      {step === 2 && (
                            <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                              <div className="col-sm-12"><h4>Client Document</h4></div>
                              <div className="row">

                                  <div className="col-sm-3 col-12">
                                    
                                    <select className="form-control" value={selectedDocumentType} onChange={handleDocumentTypeChange}>
                                      <option value="">Select Document </option>
                                      {documentType && documentType.map((docType) => (
                                        <option value={docType.doc_type_id}> {docType.document_name}</option>
                                      ))}
                                    </select>
                                    {validationErrors.selectedDocumentType && (
                                      <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedDocumentType}</div>
                                    )}
                                  </div>

                                  {selectedDocumentType ==='1' &&
                                    <div className="col-sm-3 col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={companyPanCardNo}
                                        placeholder="Enter Document No"
                                        onChange={(e) => setSelectedDocument(e.target.value)}
                                        readOnly
                                      />
                                      {validationErrors.selectedDocumentNo && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>
                                          {validationErrors.selectedDocumentNo}
                                        </div>
                                      )}
                                    </div>
                                  }

                                  {selectedDocumentType ==='4' &&
                                    <div className="col-sm-3 col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={companyGstNumber}
                                        placeholder="Enter Document No"
                                        onChange={(e) => setSelectedDocument(e.target.value)}
                                        readOnly
                                      />
                                      {validationErrors.selectedDocumentNo && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>
                                          {validationErrors.selectedDocumentNo}
                                        </div>
                                      )}
                                    </div>
                                  }

                                 

                                  {selectedDocumentType !== '1' && selectedDocumentType !== '4' &&
                                    <div className="col-sm-3 col-12">
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={selectedDocumentNo}
                                        placeholder="Enter Document No"
                                        onChange={(e) => setSelectedDocument(e.target.value)}
                                        onInput={(e) => (e.target.value = e.target.value.toUpperCase())}
                                      />
                                      {validationErrors.selectedDocumentNo && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>
                                          {validationErrors.selectedDocumentNo}
                                        </div>
                                      )}
                                    </div>
                                  }



                                  

                                  <div className="col-sm-3 col-12">

                                    <input className="form-control" type="file" multiple onChange={handleFileChange} />

                                    {validationErrors.documentFile && (
                                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.documentFile}</div>
                                    )}
                                  </div>

                                  <div className="col-sm-3 col-12">
                                    <button className="btn-primary btn-sm" onClick={uplodeDocument}>
                                      Upload Document!
                                    </button><br/>
                                    <p style={{color:'red',fontSize:"12px"}}>Click to Upload Document button for uploading a document. </p>
                                  </div>
                              </div>

                              <div className="row" style={{marginTop:'30px'}}>
                                
                                  {clientDocDetails && clientDocDetails.map((docDetails)=>(
                                      <div className="row">
                                        <div className="col-sm-12">
                                          <h5>{docDetails.document_name} ({docDetails.doc_number})</h5>
                                        </div>

                                        {docDetails.doc_url && docDetails.doc_url.split(',').map((item,index) => 
                                          <div className="col-sm-3" style={{margin:'10px'}}>
                                            <iframe src={docurl() + item} title="company policy" width="200px" height="200px"></iframe>
                                          </div>
                                        )}
                                      </div>
                                  ))}
                                
                              </div>
                                  
                            </div>
                        )}
                      
                      <div className='card-body' id="clientDiv" style={{overflowY: "hidden"}}>
                        <div className="d-flex justify-content-between">
                          {step > 1 && (
                            <>
                            <Button variant="secondary" onClick={handlePrevious}>
                              Previous
                            </Button>
                            <span style={{fontSize:'15px',color:'red'}}>After successfully uploading all required documents, the Submit button will be displayed.</span>
                            </>
                          )}

                          

                          {step < 2 ? (
                            <Link  className="btn btn-primary" onClick={handleNext}>
                              Next
                            </Link>
                            
                          ) : (
                            <>
                            {orgTypeId ==2 ? (
                              <>
                                {clientDocDetails.length >= '5' &&
                                  <Link className ="btn btn-primary" type="button" onClick={handleFormSubmit}>
                                    Submit
                                  </Link>
                                }
                              </>
                            ):(
                              <>
                              {/* {documentType && documentType.length === clientDocDetails.length && 
                                
                                <Link className ="btn btn-primary" type="button" onClick={handleFormSubmit}>
                                  Submit
                                </Link>
                              } */}

                              {clientDocDetails.length >= documentType.length && 
                                <Link className="btn btn-primary" type="button" onClick={handleFormSubmit}>
                                  Submit
                                </Link>
                              }
                              </>
                            )}
                           </>
                            )}
                        </div>
                      </div>
                      </form>
                    </div>
                </>
                }

                {active ==='Company Details' &&
                  <>
                    <div className='card-body' id="clientDetails" style={{overflowY: "hidden"}}>
                      <table className="table table-bordered table-hovered">
                        <tr>
                          <td>S.No</td>
                          <td>Client Name</td>
                          <td>Business Name</td>
                          <td>Email</td>
                          <td>Mobile No</td>
                          <td>City</td>
                          <td>State</td>
                          <td>Zipcode</td>
                          <td>Address</td>
                          <td>Organization Type</td>
                          <td>Executive Id</td>
                          <td>Created date</td>
                        </tr>

                        {matureClientDetails && matureClientDetails.map((mcd,index)=>(
                          <tr>
                              <td>{index + 1}</td>
                              <td>{mcd.client_name}</td>
                              <td>{mcd.business_name}</td>
                              <td>{mcd.email}</td>
                              <td>{mcd.mobile_no}</td>
                              <td>{mcd.city}</td>
                              <td>{mcd.state}</td>
                              <td>{mcd.pin_no}</td>
                              <td>{mcd.address}</td>
                              <td>{mcd.pan_card}</td>
                              <td>{mcd.exe_id}</td>
                              <td>{mcd.created_at}</td>
                              
                          </tr>
                          ))
                        }
                      </table>
                    </div>
                  </>
                }

                
              {/* {active ==='Services' &&
                <>
                    <div>
                        <div className='card-body' style={{overflowY: "hidden"}}>
                            <div className="row">

                              <div className="col-sm-3">
                                <select value={prePackageId} onChange={(e)=>{setPrePackageId(e.target.value)}} className="form-control">
                                  <option value=''>Select Category</option>
                                  {packageCategory && packageCategory.map((pc)=>( 
                                    <option value= {pc.id}> {pc.name}</option>
                                  ))} 
                                </select>
                              </div>

                              <div class="col-sm-3">
                                  <select className="form-control"
                                    value={selectedCity}
                                    onChange={(e) => setSelectedCity(e.target.value)}
                                    >
                                    <option value=''>Select City (Group)</option>
                                    {cityList.map((city) => (
                                    <option key={city.group_id} value={city.group_id}>
                                    {city.name}
                                    </option>
                                    ))}
                                </select>
                              </div>

                              <div className="col-sm-3">
                                <select className="form-control" value={selectedProduct}
                                      onChange={(e) => setSelectedProduct(e.target.value)}>
                                      <option value=''>Select Product</option>
                                      {product.map((pro) => (
                                      <option key={pro.id} value={pro.id}>
                                      {pro.product_name}
                                      </option>
                                      ))}
                                </select>
                              </div>
                              
                              <div className="col-sm-3">
                                <select className="form-control"
                                    value={selectedService}
                                    onChange={(e) => setSelectedService(e.target.value)}
                                    >
                                    <option value=''>Select Service</option>
                                    {service.map((ser) => (
                                    <option key={ser.id} value={ser.id}>
                                    {ser.service_name}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            </div>
                            <div className="d-flex flex-wrap">
                              <>
                                {prePackages && prePackages.map((pp)=>(
                                  <>
                                    <div className="col-sm-4 col-12 px-sm-2 px-0 py-2">
                                      <div className='border p-2 rounded'>
                                        <div style={{padding: '10px 20px',backgroundColor: 'rgb(29 95 163)',fontWeight: '600',textAlign: 'center',color:'white'}}> 
                                           {pp.package_name}
                                        </div>

                                        <div style={{textAlign:'center',fontWeight: '600',fontSize:'15px'}}>
                                          {pp.category_name_list && pp.category_name_list.split(',').join(" + ")}
                                        </div>

                                        <div> 
                                            <h5>&nbsp;&nbsp;
                                            {pp.duration_name} ( {pp.package_type_name} {pp.total_lead} Lead)
                                            </h5>
                                        </div>

                                        <div>
                                           
                                        </div>
                                        <div>
                                        
                                        <table style={{width:'100%'}}>
                                          {pp.assign_cat.map((cat)=>(
                                            <tr>
                                              <td> &nbsp;&nbsp;✔ &nbsp; {cat.name}</td>
                                              <td style={{float:'rigth'}}>{cat.type_status===1?'✅':"❌"}
                                              &nbsp;&nbsp;
                                              </td>
                                            </tr>
                                          ))}
                                          </table>	
                                        </div>

                                        <div>
                                          <h6>
                                            &nbsp;&nbsp; Is Partial Payment : {pp.is_partial_payment==1 ? 'Yes':'No'}
                                          </h6>
                                        </div>
                                        
                                        <div>
                                          <h5> &nbsp;&nbsp;Package Price : {pp.package_price}</h5>
                                        </div>

                                        <div className='d-flex justify-content-end' >
                                        <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30px" }} onClick={() => viewOfferDetails(pp.id, pp.category_name, pp.category_name_list, pp.duration_name, pp.package_price, pp.package_type_name, pp.total_lead, pp.is_partial_payment, pp.package_name, pp.city_id, pp.group_id)}>
                                        <span class="material-icons-outlined" title="View Package Details">visibility</span>
                                        
                                      </button>

                                         {loading && selectedProposalId == pp.id? (
                                              <p style={{align:'center'}}>
                                              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                                              </p>
                                          ) : (
                                          <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{float:'right',margin:'10px',width:"40px",height:"40px"}} onClick={() => sentPurposal(pp.id)} >
                                            <span class="material-icons-outlined" alt="" >share</span>
                                           </button> 
                                        )}
                                        </div>
                                      </div>
                                    </div>
                                    </>
                                  ))}
                                  </>
                          </div>
                        </div>
                    </div>
                </>
                } */}

{active === 'Services' &&
                <>
                  <div>
                  <LoadingModal isLoading={isLoading} />

                    <div className='card-body' style={{ overflowY: "hidden" }}>
                      <div className="row">

                        <div className="col-sm-3">
                          <select value={prePackageId} onChange={(e) => { setPrePackageId(e.target.value) }} className="form-control">
                            <option value=''>Select Category</option>
                            {packageCategory && packageCategory.map((pc) => (
                              <option value={pc.id}> {pc.name}</option>
                            ))}
                          </select>
                        </div>

                        <div class="col-sm-3">
                          <select className="form-control"
                            value={selectedCity}
                            onChange={(e) => setSelectedCity(e.target.value)}
                          >
                            <option value=''>Select City (Group)</option>
                            {cityList.map((city) => (
                              <option key={city.group_id} value={city.group_id}>
                                {city.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-sm-3">
                          <select className="form-control" value={selectedProduct}
                            onChange={(e) => setSelectedProduct(e.target.value)}>
                            <option value=''>Select Product</option>
                            {product.map((pro) => (
                              <option key={pro.id} value={pro.id}>
                                {pro.product_name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="col-sm-3">
                          <select className="form-control"
                            value={selectedService}
                            onChange={(e) => setSelectedService(e.target.value)}
                          >
                            <option value=''>Select Service</option>
                            {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                                {ser.service_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">

                        {/*   {activeMenu1 ==='Lmart Packages' && */}
                        <>

                          {prePackages && prePackages.map((pp) => (
                            <>
                              <div className="col-sm-4 col-12 px-sm-2 px-0 py-2">
                                <div className='border p-2 rounded'>
                                  <div style={{ padding: '10px 20px', backgroundColor: 'rgb(29 95 163)', fontWeight: '600', textAlign: 'center', color: 'white' }}>
                                    {pp.package_name} {pp.active_offers > 0 ? <span style={{ color: 'red', fontSize: '25px', fontWeight: '600' }}>offer * </span> : ''}
                                  </div>

                                  <div style={{ textAlign: 'center', fontWeight: '600', fontSize: '15px' }}>
                                    {pp.category_name_list && pp.category_name_list.split(',').join(" + ")}
                                  </div>

                                  <div>
                                    <h5>&nbsp;&nbsp;
                                      {pp.duration_name} ({pp.package_type_name} {pp.total_lead} Lead)
                                    </h5>
                                  </div>

                                  <div>

                                  </div>
                                  <div>

                                    <table style={{ width: '100%' }}>
                                      {pp.assign_cat.map((cat) => (
                                        <tr>
                                          <td> &nbsp;&nbsp;✔ &nbsp; {cat.name}</td>
                                          <td style={{ float: 'rigth' }}>{cat.type_status === 1 ? '✅' : "❌"}
                                            &nbsp;&nbsp;
                                          </td>
                                        </tr>
                                      ))}
                                    </table>
                                  </div>

                                  <div>
                                    <h6>
                                      &nbsp;&nbsp; Is Partial Payment : {pp.is_partial_payment == 1 ? 'Yes' : 'No'}
                                    </h6>
                                  </div>

                                  <div>
                                    <h5> &nbsp;&nbsp;Package Price : {pp.package_price}</h5>
                                  </div>

                                  <div className='d-flex justify-content-between align-items-center'>
                                    <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30px" }} onClick={() => viewOfferDetails(pp.id, pp.category_name, pp.category_name_list, pp.duration_name, pp.package_price, pp.package_type_name, pp.total_lead, pp.is_partial_payment, pp.package_name, pp.city_id, pp.group_id)}>
                                    <span class="material-icons-outlined" title="View Package Details">visibility</span>
                                    
                                    </button>

                                    
                                  </div>
                                </div>
                              </div>
                            </>
                          ))}

                        </>
                        
                      </div>
                    </div>
                  </div>
                </>
              }
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={follwoupModal} fullWidth PaperProps={{ style: {padding: '20px' } }}>
        <DialogTitle>Client Followup</DialogTitle>
          <DialogContent style={{height:"450px"}}>
            <form>
                <div className="row" style={{overflowY: "hidden"}}>

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>Followup Details</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>

                  <div class="col-sm-6">
                    <label>Select City (Group)</label>
                    <select className="form-control"
                        value={selectedCity}
                        onChange={(e) => setSelectedCity(e.target.value)}
                        >
                        <option value=''>Select City</option>
                        {cityList.map((city) => (
                        <option key={city.group_id} value={city.group_id}>
                        {city.name}
                        </option>
                        ))}
                    </select>
                  
                  </div>

                  <div className="col-sm-6">
                    <label>Salect Product</label>
                    <select className="form-control" value={selectedProduct}
                          onChange={(e) => setSelectedProduct(e.target.value)}>
                          <option value=''>Select Product</option>
                          {product.map((pro) => (
                          <option key={pro.id} value={pro.id}>
                          {pro.product_name}
                          </option>
                          ))}
                    </select>
                    
                  </div>
                  {selectedProduct &&(
                  <div className="col-sm-6">
                      <label>Select Service</label>
                      <select className="form-control"
                              value={selectedService}
                              onChange={(e) => setSelectedService(e.target.value)}
                              >
                              <option value=''>Select Service</option>
                              {service.map((ser) => (
                              <option key={ser.id} value={ser.id}>
                              {ser.service_name}
                              </option>
                              ))}
                      </select>
                    
                  </div>
                  
                  )}

                  {category != '' && selectedService &&(
                  <div className="col-sm-6">
                      <label>Select Category</label>
                      <select className="form-control"
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.target.value)}
                          >
                          <option value=''>Select Category</option>
                          {category.map((cate) => (
                          <option key={cate.id} value={cate.id}>
                          {cate.category_name}
                          </option>
                          ))}

                        
                      </select>
                      {validationErrors.selectedCategory && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedCategory}</div>
                        )}
                      
                  </div>
                )}




                  <div className="col-sm-6">
                      <label>Call Status</label>
                      <select className="form-control"
                          value={selectedFollowCallStatus}
                          onChange={(e) => setFollCallStatus(e.target.value)}
                          >
                          <option value=''>Select Call Status</option>
                          <option value='Connected'>Connected</option>
                          <option value='Not_Connected'>Not Connected</option>
                      </select>
                     
                  </div>
            
                  {selectedFollowCallStatus &&(
                    <div className="col-sm-6">
                        <label>Followup Status</label>
                        <select className="form-control"
                          value={selectedFollowStatus}
                          onChange={(e) => setFollStatus(e.target.value)}
                          >
                          <option value=''>Select Followup Status</option>
                          {followup.map((follo) => (
                          <option key={follo.id} value={follo.id}>
                          {follo.activity_name}
                          </option>
                          ))}
                        </select>
                        
                    </div>
                  )}

                  {selectedCity && (selectedFollowStatus ==5 || selectedFollowStatus ==6 || selectedFollowStatus ==7 || selectedFollowStatus ==9) &&(
                    <div className="col-sm-12">
                        <label>Select Package</label>
                        <select className="form-control"
                          value={selectedPackage}
                          onChange={(e) => setSelectedPackage(e.target.value)}
                          >
                          <option value=''>Select Package Details</option>
                          {prePackageList.map((prePkg) => (
                          <option key={prePkg.id} value={prePkg.id}>
                          {prePkg.package_name} ({prePkg.package_price})
                          </option>
                          ))}
                        </select>
                        {validationErrors.selectedPackage && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.selectedPackage}</div>
                        )}
                    </div>
                   
                  )}
            
                
                  <div className="col-sm-6">
                      <label>Next Followup Date</label><br />
                      
                      <DatePicker className="form-control" style={{position: 'absolute'}}
                        minDate={new Date()}
                        maxDate={new Date().setDate(new Date().getDate() + parseInt(nextDays))}
                        selected={nxtFollowupDate}
                        onChange={(date) => setNxtFollowupDate(date)}
                        dateFormat="dd/MM/YYYY"
                        onKeyDown={(e) => {
                          e.preventDefault();
                      }}
                      />
                      {validationErrors.nxtFollowupDate && (
                          <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.nxtFollowupDate}</div>
                      )}
                  </div>
               

                {selectedFollowStatus  &&(
                  <div className="col-sm-12">
                    <label>Remarks</label>
                    <TextField type="text" className="form-control" value={followupRemarks} onChange={(e) => setFollowupRemarks(e.target.value)}></TextField> 
                  </div>
                )}
                </div>
            </form>
          </DialogContent>

          <DialogActions>
              <Link className="btn btn-primary btn-sm" onClick={closeFollowupControl}>Close</Link>

              <Link className="btn btn-success btn-sm" onClick={submitFollowupForm}>Create Followup</Link>

            
          </DialogActions>
      </Dialog>

      <Dialog open={packageby} PaperProps={{ style: { padding: '20px', maxWidth: '90%', width: '900px' } }}>
       
        <DialogContent style={{ height: 'auto' }}>
          <div>
            Package Name: <b>{packagedetails.package_name}</b> <br />
            Services: <b>{packagedetails.service_name}</b> <br />
            Package Duration: <b>{packagedetails.duration_name}</b> <br />
            Total Lead: <b>{packagedetails.total_lead}</b> <br />
            Package Price with Tax Include : <b>{packagedetails.price}</b> <br />
            Tax: <b>{packagedetails.taxAmount}</b> <br />
          </div>

          <table className="table table-bordered table-hovered">
            <thead>
              <tr>
                <td colSpan="7"><b>Offer Details</b></td>
              </tr>
              <tr>
                <td>S.No</td>
                <td>Offer Title</td>
                <td>Offer Type</td>
                <td>Percent</td>
                <td>Valid To</td>
              </tr>
            </thead>
            <tbody>
              {packagedetails.offerDetails &&
                packagedetails.offerDetails.map((od, index) => (
                  <tr key={index}>
                    <td>
                      <input
                        type="checkbox"
                        checked={checkedOffers === od.id}
                        onChange={() => handleOfferCheck(od.id)}
                      />
                    </td>
                    <td>{od.offer_title}</td>
                    <td>
                      {od.offer_discount_type.split(',').map((type, idx) => {
                        const isPrice = type.trim() === '1';
                        const isLead = type.trim() === '2';

                        return (
                          <div key={idx}>
                            <input
                              type="radio"
                              id={`offerType_${od.id}_${type}`}
                              name={`offerType_${od.id}`}
                              value={type.trim()}
                              disabled={checkedOffers !== od.id}
                              checked={selectedOfferTypes[od.id] === type.trim()}
                              onChange={() => handleOfferTypeChange(od.id, type.trim())}
                            />
                            <label htmlFor={`offerType_${od.id}_${type}`}>
                              {isPrice ? 'Price' : isLead ? 'Lead' : ''}
                            </label>
                          </div>
                        );
                      })}
                    </td>
                    <td>
                      {selectedOfferTypes[od.id] === '1' &&
                        od.offer_price_percent.split(',').map((pricePercent, idx) => (
                          <div key={idx}>

                            <input
                              type="radio"
                              id={`pricePercent_${od.id}_${pricePercent}`}
                              name={`percent_${od.id}`}
                              value={pricePercent.trim()}
                              checked={selectedPercent[od.id] === pricePercent.trim()}
                              onChange={() => handlePercentChange(od.id, pricePercent.trim())}
                            />
                            <label htmlFor={`pricePercent_${od.id}_${pricePercent}`}>
                              {pricePercent.trim() == 1 ? ' 5 %' : pricePercent == 2 ? '10 %' : pricePercent == 3 ? '15 %' : ''}
                            </label>
                          </div>
                        ))}

                      {selectedOfferTypes[od.id] === '2' &&
                        od.offer_lead_percent.split(',').map((leadPercent, idx) => (
                          <div key={idx}>
                            <input
                              type="radio"
                              id={`leadPercent_${od.id}_${leadPercent}`}
                              name={`percent_${od.id}`}
                              value={leadPercent.trim()}
                              checked={selectedPercent[od.id] === leadPercent.trim()}
                              onChange={() => handlePercentChange(od.id, leadPercent.trim())}
                            />
                            <label htmlFor={`leadPercent_${od.id}_${leadPercent}`}>
                              {leadPercent.trim() == 4 ? ' 5%' : leadPercent.trim() == 5 ? ' 10%' : leadPercent.trim() == 6 ? ' 15%' : leadPercent.trim() == 7 ? ' 20%' : ''}
                            </label>
                          </div>
                        ))}
                    </td>
                    <td>{od.valid_to}</td>
                  </tr>
                ))}
            </tbody>
          </table>

          {discountPercent && selectedOfferTypes && discountType == 2 &&
            <div className="row">

              <div className="col-sm-6">
                <label>Discount % </label>
                <input type="text" className='form-control' value={discountPercent} onChange={(e) => { setApplyDiscountPercent(e.target.value) }} readOnly />
              </div>
              <div className="col-sm-6">
                <label>Extra Lead </label>
                <input type="text" className='form-control' value={Math.round(packagedetails.total_lead * discountPercent / 100)} readOnly />
              </div>

              <div className="col-sm-6">
                <label>After Discount Lead</label>
                <input
                  type="text"
                  className="form-control"
                  value={afterDiscountLead}
                  readOnly
                />
              </div>


            </div>
          }



          {discountPercent && selectedOfferTypes && discountType === 1 && (
            <div className="row">


              <div className="col-sm-6">
                <label>Discount %</label>
                <input type="text" className="form-control" value={discountPercent} onChange={(e) => { setApplyDiscountPercent(e.target.value) }} readOnly />
              </div>



              <div className="col-sm-6">
                <label>Offer Payable Amount</label>
                <input
                  type="text"
                  className="form-control"
                  value={paybleAmountAfterDiscount}
                  readOnly
                />
              </div>
            </div>
          )}


          <div className="row">
            {packagedetails.isPartialPayment === '1' ? (
              <div className="col-sm-6">
                <label>Is Partial Payment:</label>
                <br />
                <input
                  type="radio"
                  name="isPartialPayment"
                  onChange={(e) => handlePartialPaymentChange(1)} 
                />
                Yes &nbsp; &nbsp; &nbsp; &nbsp;

                <input
                  type="radio"
                  name="isPartialPayment"
                  onChange={(e) => handlePartialPaymentChange(0)} 
                />
                No &nbsp; &nbsp; &nbsp; &nbsp;
                {validationErrors.partialPayment && (
                  <div className="text-danger" style={{ fontSize: '15px' }}>
                    {validationErrors.partialPayment}
                  </div>
                )}
              </div>


            ) : (
              <div className="col-sm-6">
                <label>Is Partial Payment:</label>
                <br />
                <input
                  type="radio"
                  name="isPartialPayment"
                  checked
                  onChange={() => handlePartialPaymentChange(0)}
                />
                No &nbsp; &nbsp; &nbsp; &nbsp;
               
              </div>
              
            )}

            <div className="col-sm-6">
              <label>Select Company</label>
              <select
                className="form-control"
                value={selectedComp}
                onChange={(e) => setSelectedComp(e.target.value)}
              >
                <option value="">Select Company</option>
                <option value="New">New</option>
              </select>
              {validationErrors.compDetails && (
                <div className="text-danger" style={{ fontSize: '15px' }}>
                  {validationErrors.compDetails}
                </div>
              )}
            </div>

            <div className="col-sm-6">
              <label>Registration Amount</label>
              <input type="text" className="form-control" value={regAmount} readonly />
            </div>

            <div className="col-sm-6">
              <label>Client Type</label>
              <input type="text" className="form-control" value={companyType} readonly />
            </div>
            
            <div className="col-sm-6">
              <label>Payable Amount</label>
              <input
                type="text"
                className="form-control"
                value={payblePackageAmount}
                readOnly
              />
            </div>


            <div className="col-sm-6">
              <label>Deposit Amount</label>
              <input
                type="text"
                className="form-control"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                style={{ width: '250px' }}
              />
              {validationErrors.depositAmount && (
                <div className="text-danger" style={{ fontSize: '15px' }}>
                  {validationErrors.depositAmount}
                </div>
              )}
            </div>


            <div className="col-sm-6">
              <span
                className="btn btn-primary btn-sm"
                style={{ marginTop: '35px' }}
                onClick={() => {
                  // Define variables for arguments based on conditions
                  let price = payblePackageAmount;
                  let totalLead = packagedetails.total_lead;
                  let partial = partialPayment;
                  // Check conditions to update the arguments
                  if(discountType === 1 && checkedOffers)
                  {
                    price = payblePackageAmount;
                    totalLead = packagedetails.total_lead;
                    partial = partialPayment;
                  } else if (discountType === 2 && checkedOffers) {
                    price = payblePackageAmount;
                    totalLead = afterDiscountLead;
                    partial = partialPayment;
                  }
                  // Call the function with the determined arguments
                  checkTaxAmount(price, totalLead, partial, regAmount);
                }}
              >
                Calculate Tax Amount
              </span>
            </div>
          </div>

          <br />

          <table className="table table-bordered table-hovered">
            <thead>
              <tr>
                <td>Tax Amount</td>
                <td>Balance Amount</td>
                <td>Due Leads</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={taxAmount}
                    style={{ width: '150px' }}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={balanceAmount}
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={dueLeads}
                    style={{ width: '150px' }}
                    readOnly
                  />
                </td>
              </tr>

              
            </tbody>
          </table>
        </DialogContent>
          <p style={{color:'red'}}>{discountType ==1 ?<h5>You will save ₹ {packagedetails.price - (payblePackageAmount - regAmount)} on this package.</h5>:discountType ==2?<h5>You will get an extra {afterDiscountLead - packagedetails.total_lead} leads with this package.</h5>:''}</p>
        <DialogActions className='d-flex justify-content-between' >

          {loading ? (
            <p style={{ align: 'center' }}>
              <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
            </p>
          ) : (
            <button className="btn btn-primary btn-sm d-flex align-items-center justify-content-center" style={{ float: 'right', margin: '5px', width: "30px", height: "30px" }} onClick={() => sentPurposal(packagedetails.package_id,packagedetails.price,payblePackageAmount,discountType,discountPercent,regAmount,packagedetails.total_lead)}>
              <span class="material-icons-outlined" title="Send Proposal">share</span>
            </button>
          )}

          
          {packageloading ? (
            <p style={{ color: 'red' }}>Please do not refresh the page while the package creation process is in progress. <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img></p>
          ) : (
            <>
            

              <button className="btn btn-info btn-sm" onClick={closePackageModel}>
                Close
              </button>
             
            </>
          )}
        </DialogActions>
      </Dialog>
      </DashboardLayout>
    )
}

export default ClientProfile;

import React, { useState, useEffect } from 'react';
import { Link, Navigate, useParams,useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dashboardApi from 'layouts/common_components/apibase_url';
import secureLocalStorage from 'react-secure-storage';
import DashboardLayout from 'theme_layout/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'theme_layout/Navbars/DashboardNavbar';
import Footer from 'theme_layout/Footer';
import ReactPaginate from 'react-paginate';
import MDBox from 'components/MDBox';

const Notifications = () => {
  const empId = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 10;

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  useEffect(() => {
    getNotificationList();
  }, [currentPage]);

  const getNotificationList = async () => {
    setLoading(true);
    try {
      const endpoint = `notification-history/${empId}?page=${currentPage + 1}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        //setData(response.data.data);
        setData(response.data.data.data || response.data.data);
        setTotalPages(response.data.last_page || 1);
        //setTotalPages(response.data.data.totalPages);
      } else {
        console.error('Error fetching data:', response.data.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (redirect, id) => {
    try {
      const response = await dashboardApi.post('notification-seen-status', {
        notification_id:id, 
      });
  
      if (response.data.status === 200) {
        navigate(redirect);
      } else {
        console.error("Error updating status:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching:", error);
    }
  };
  


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox p={3}>
        <Card>
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Notification History
            </Typography>
            {loading ? (
              <MDBox display="flex" justifyContent="center" alignItems="center" height="200px">
                <CircularProgress />
              </MDBox>
            ) : (
              <>
                {data ? (
                  data.map((row, index) => (
                    <Card key={index} variant="outlined" sx={{ mb: 2, p: 2 }}>
                      <Typography variant="h6">{row.notification}</Typography>
                      <Typography variant="h6" color="textSecondary">
                         {new Date(row.date).toLocaleString()} -{" "}
                          {row.status === 0 ? "Not Seen" : "Seen"}

                          <span 
                            style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline',paddingLeft:'700px'}} 
                             onClick={() => handleClick(row.redirect, row.id)}
                           >
                           Click to view
                          </span>
                      </Typography>

                    </Card>
                  ))
                ) : (
                  <Typography variant="body1" color="textSecondary">
                    No notifications found.
                  </Typography>
                )}
              </>
            )}

            {totalPages > 1 && (
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                containerClassName={"pagination"}
                activeClassName={"active"}
                pageLinkClassName={"page-link-style"}
                breakLinkClassName={"break-link-style"}
                previousLinkClassName={"prev-link-style"}
                nextLinkClassName={"next-link-style"}
              />
            )}
          </CardContent>
        </Card>
      </MDBox>
      <Footer />
      <ToastContainer />
    </DashboardLayout>
  );
};

export default Notifications;

import React, { useEffect, useState } from "react";
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";

const IdleTimer = () => {
  const [idleTime, setIdleTime] = useState(0);
  const [totalIdleTime, setTotalIdleTime] = useState(0);
  const [idleStartTime, setIdleStartTime] = useState(null);
  const [breakStatus, setBreakStatus] = useState(null);
  const empId = secureLocalStorage.getItem("emp_id");

  const formatDateTime = (date) => {
    if (!date) return "";
    
    const localDate = new Date(date);
    localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
    return localDate.toISOString().slice(0, 19).replace("T", " ");
  };

  const resetTotalIdleTime = () => {
    setTotalIdleTime(0);
    setIdleTime(0);
    setIdleStartTime(null);
    localStorage.setItem("lastActiveTime", Date.now().toString()); 
  };

  const checkIdleTime = () => {
    setIdleTime((prevTime) => {
      if (prevTime === 0) {
        setIdleStartTime(new Date());
      }
      return prevTime + 1;
    });

    setTotalIdleTime((prevTime) => prevTime + 1);
  };

  const storeIdleTimeInDB = async (startTime, endTime, duration) => {
    if (!startTime || !endTime) return;
    try {
      await dashboardApi.post("/store-idle-time", {
        start_time: formatDateTime(startTime),
        end_time: formatDateTime(endTime),
        idle_time: Math.floor(duration / 60),  
        emp_id: empId,
      });
      console.log("Idle time stored in DB:", formatDateTime(startTime), formatDateTime(endTime));
    } catch (error) {
      console.error("Error storing idle time:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dashboardApi.get(`get-break-time-status/${empId}`);
        if (response.data.status === 200) {
          setBreakStatus(response.data.break_status);
        } else {
          console.error("Error fetching data:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [empId]);  

  useEffect(() => {
    let idleInterval = setInterval(() => {
      checkIdleTime();
    }, 1000);

    const events = ["mousemove", "keydown", "click"];
    events.forEach((event) => {
      window.addEventListener(event, resetTotalIdleTime);
    });

    const syncIdleTime = (event) => {
      if (event.key === "lastActiveTime") {
        resetTotalIdleTime();
      }
    };
    window.addEventListener("storage", syncIdleTime);

    return () => {
      clearInterval(idleInterval);
      events.forEach((event) => {
        window.removeEventListener(event, resetTotalIdleTime);
      });
      window.removeEventListener("storage", syncIdleTime);
    };
  }, []); 

  useEffect(() => {
    if (idleTime >= 60 && breakStatus === 0) {
      const idleEndTime = new Date();
      storeIdleTimeInDB(idleStartTime, idleEndTime, idleTime);
      setIdleTime(0);
      setIdleStartTime(null);
    }
  }, [idleTime, breakStatus, idleStartTime]); 

  return (
    <div>
      {breakStatus === 0 && (
        <span style={{ color: "red", fontSize: "20px", padding: "5px" }}>
          System Idle Time: {totalIdleTime} seconds
        </span>
      )}
    </div>
  );
};

export default IdleTimer;

import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import superadminroute from "superadminroutes";
import employeeroute from "employeeroutes";
import empprofileroutes from "empprofileroutes";
import AdminProcess from "adminprocess";
import Stockroutes from "stock";
import Candidate_followup from 'candidatefollowup';
import  secureLocalStorage  from  "react-secure-storage";
import CompanyPolicy from 'layouts/company_policy';
import Icon from "@mui/material/Icon";
import KraKpi from "krakpiroutes";
import Companytarget from "companytargetroute";
import SalesFunnelRoutes from 'sales_funnelroutes';
import financeroute from  "financeroute";
import SalesDashboard from "layouts/dashboard/sales_dashboard";
import HrDashboard from "layouts/dashboard/hr_dashboard";
import csroute from "csroute";
import offersroute from "offersroute";
import CsDashboard from "layouts/dashboard/cs_dashboard";
import DmDashboard from "layouts/dashboard/digital_dashboard";

import SuperAdminDashboard from "layouts/dashboard/superadmin_dashboard";
import adminroute from "adminroute";
import salesroute from "salesroutes";
import reportsroute from "reportsroute";
import ClientRequestDetails from "layouts/sales/moving/client_request_details";
//import salesadminroute from "salesadminroutes";
//import salesroute from "salesroutes";
import salesadminroute from "salesadminroutes";
const emp_id = secureLocalStorage.getItem('emp_id');
const dept_id = secureLocalStorage.getItem('department_id');
const cs_panel_access = secureLocalStorage.getItem("cs_panel_access");
//alert(dept_id);
const token = secureLocalStorage.getItem('token');
const permission = secureLocalStorage.getItem('permission');
const parsedPermission = permission ? JSON.parse(permission) : [];
const emp_profile = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "view-emp-profile");
const superadmin_module = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "full superadmin module");
const employee_module = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "full Hr-admin-module");
const kra_and_kpi = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "kra & kpi");
let dashboard = '';
if(dept_id==3){
   dashboard = <SalesDashboard />;

}
else if(dept_id==9){
  dashboard = <SuperAdminDashboard/>;

}
else if(dept_id ==5){
  dashboard = <HrDashboard/>
}
else if(dept_id ==4){
  dashboard = <CsDashboard/>

}

else if(dept_id ==10){
  dashboard = <DmDashboard/>

}

else{
  dashboard = <Dashboard />;

}

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: (token!=null) ? dashboard : <SignIn/> ,
    nested:[],
  },
  {
    type: "collapse",
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: (token!=null) ?  <Notifications /> : <SignIn/> ,
    nested:[],
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: (token!=null) ?  <Profile /> : <SignIn/> ,
    nested:[],
  },
   ...Companytarget,
   ...empprofileroutes,
   ...Candidate_followup,
  
   //...SalesFunnelRoutes,

   {
    route: "/company-policy",
    component: (token!=null) ?  <CompanyPolicy/> : <SignIn/> ,
    nested:[],
  },
  {
    icon: <Icon fontSize="small">login</Icon>,
    route: "/sign-in",
    component: (token!=null) ?   <SignIn /> : <SignIn/> ,
  },
];

    routes.push({
      type: "collapse",
      name: "Request Details",
      key: "request-details",
      icon: <Icon fontSize="small">notifications</Icon>,
      route: "/client-request-details",
      component: token != null ? <ClientRequestDetails /> : <SignIn />,
      nested:[],
    });

if(emp_profile){
routes.push({
    route: "/view-emp-profile/:id",
    component: (token!=null) ?  <Profile /> :  <SignIn/> ,
    nested:[],
});
}
if(kra_and_kpi){
  routes.push(
    ...KraKpi,
   );

}
if(superadmin_module){
routes.push(
   ...superadminroute,
  );
}
if(employee_module){
routes.push(
  ...employeeroute,
 );
}

if((dept_id ==4 && cs_panel_access=='Yes') || dept_id==9){
  routes.push(
    ...csroute,
   );

}

if(dept_id ==3 || dept_id==9){
  routes.push(
    ...salesroute,
   );

}
if(dept_id==9){
  routes.push(
    ...salesadminroute,
    ...offersroute,
   );

}
if(dept_id ==15 || dept_id==9){
  routes.push(
    ...Stockroutes,
    ...adminroute,
   );
}

if(dept_id == 9 || dept_id == 8){
  routes.push(
    ...financeroute,
   );
  
}

if(dept_id == 9 || dept_id == 10 || dept_id == 4 ){
  routes.push(
    ...reportsroute,
   );
  
}

export default routes;

import React,{ useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import {useNavigate } from "react-router-dom";
import Switch from "react-switch";
import dashboardApi from "layouts/common_components/apibase_url";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";

function TeamDetails() {
  const token = secureLocalStorage.getItem('token');
  const emp_id = secureLocalStorage.getItem('emp_id');
  const navigate = useNavigate();
  const [team, getteam] = useState([]);
  const [loading, setLoading] = useState(true);
  const[permission,Setpermission] = useState();
  const[newdate,setnewdate] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(1);
   useEffect(() => {
   if (!token) {
    navigate("/sign-in");
   // return null;
  }
  }, [token, navigate]);
    const handleStatusChange = (index, value) => {
    const updatedteamData = [...team];
    updatedteamData[index].is_confirmed = value;
    getteam(updatedteamData);
  };
  const handleReasonChange = (e) => {
    setnewdate(e.target.value);
  };

  const handleSaveButtonClick = async (empId, index, value) => {
    try {
      const response = await dashboardApi.post("change-confirmation-status", {
        empId,
        confirmation: value,
        date: value === "0" ? newdate : "", 
        action_by:emp_id,
      });

      if (response.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: response.data.message,
        });
        fetchEmployee();
      } else {
        console.error("Error updating leave status:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };
  useEffect(()=>{
        fetchEmployee() 
    },[currentPage])
   const fetchEmployee = async () => {
    try {
      const response = await dashboardApi.post('view-team', {
        id: emp_id,
        per_page:postPerPage,
        page: currentPage + 1,
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const employeeData = responseData.data || responseData;
        getteam(employeeData);
        setNumber(response.data.last_page);

      } else {
        console.error('Error fetching employee:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching employee:', error);
      setLoading(false);
    }
  };
 useEffect(() => {
  const fetchData = async () => {
    try {
      const endpoint = `get-permission/${emp_id}`;
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        Setpermission(response.data.data);
      } else {
        console.error('Error fetching data:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, []);
const handlePageChange = (newPage) => {
    setCurrentPage(newPage.selected);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                <div style={{ overflowY: 'hidden' }}>
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <table className="table table-bordered table-hovered">
                 <tr>
                  <td>S.No</td>
                  <td>Emp Name</td>
                  <td>Company</td>
                  <td>Branch</td>
                  <td>Department</td>
                  <td>Designation</td>
                  <td>Date of Joining</td>
                  <td>Date of confirmation</td>
                  <td>Change Confirmation Status</td>
                  <td style={{minWidth: '200px' }}>Action</td>
            
               </tr>
               {team.map((employess, index) => (
                <tr key={employess.id}>
                  <td>{index + 1}</td>
                  <td>{employess.emp_fname}</td>
                  <td>{employess.business_name}</td>
                  <td>{employess.branch_name}</td>
                  <td>{employess.department_name}</td>
                  <td>{employess.designation_name}</td>
                  <td>{employess.emp_doj}</td>
                  <td>{employess.emp_doc}</td>
                  <td style={{minWidth:'200px'}}>
                  <select
                       className="form-control"
                        value={employess.is_confirmed || ""}
                        onChange={(e) => handleStatusChange(index, e.target.value)}
                    >
                    <option value="">Change Status</option>
                    <option value="1">Confirmed</option>
                    <option value="0">postponed</option>
                    </select>
                    {employess.is_confirmed === '0' && (
                             <input
                             type="date"
                              className="form-control"
                              onChange={handleReasonChange}
                              value={newdate}
                              required
                               />
                             )}
                      <button
                      variant="primary"
                      className="mt-2 ms-6 btn btn-info"
                      size="lg"
                      block="block"
                       type="button"
                       onClick={() =>
                      handleSaveButtonClick(employess.emp_id, index, employess.is_confirmed)
                      }
                       >
                      Save
                    </button>
                    </td>

                        <td>
                 <Link to={`/view-emp-profile/${employess.emp_id}`} className='btn btn-success btn-sm me-2'>
                             View
                  </Link>
                  &nbsp;
                  <Link to={`/emp-salary-history/${employess.emp_id}`} className='btn btn-sm btn-success me-2'>
                             History
                  </Link>
                   &nbsp; &nbsp;
                  </td>
                  
                  
               </tr>
              ))}

            </table>
             )}

                </div>
                
                  {team.length > 0 && (
                   <Pagination className="custom-pagination">
                      <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageChange}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
               )}
                
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default TeamDetails;
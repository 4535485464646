import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AdminDetailsHeader from 'layouts/common_components/admin_details_header';
import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from 'layouts/loaderModal/LoadingModal';
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';

function Admin_company_details() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [companyDetails,setCompanyDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  
  const handlePageClick = (selected) =>
  {
    setCurrentPage(selected.selected);
  };

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
  
    useEffect(() => {
      getCompanyDetailsList();
    }, [currentPage]);

    const getCompanyDetailsList = async () => 
    {
      setIsLoading(true);
      try
      {
        const response = await dashboardApi.get(`get-admin-company-details-list`, {
          params: {
            emp_id: emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
          }
        });

        if(response.data.status === 200)
        {
          setCompanyDetails(response.data.data.data || []); 
          setNumber(response.data.data.last_page || 1);
          setDateFrom(response.data.from_date ? response.data.from_date : '');
          setDateTo(response.data.to_date ? response.data.to_date : '');
          setIsLoading(false);
        }
        else
        {
          setIsLoading(false);
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        setIsLoading(false);
        console.error('Error fetching countries:', error);
      }
    };
    const [attemptsModal ,setAttemptsModal]= useState(false);
    const [company_id ,setCompanyId]= useState('');
    const [company_name ,setCompanyName]= useState('');
    const [verifiedStatus ,setVerifiedStatus]= useState('');
    const [verifiedRemarks ,setVerifiedRemarks]= useState('');
    const [validationErrors, setValidationErrors] = useState({});
     const [popupMessage, setPopupMessage] = useState("");
     const [isError, setIsError] = useState(false);

    
        

    const viewAttemptDetails =(comp_id,comp_name)=>
    {
      setAttemptsModal(true);
      setCompanyId(comp_id);
      setCompanyName(comp_name);
    }

    const closeAttemptsModal = ()=>
    {
      setCompanyId('');
      setCompanyName('');
      setAttemptsModal(false);
      
    }

    const saveVerifiedStatus =async ()=>
    {
     
      if(!verifiedStatus)
      {
        showPopup("Select verified status", true);
        return;
      }

      if(!verifiedRemarks)
      {
        showPopup("Enter verified remarks", true);
        return;
      } 


      try
      {
        const response = await dashboardApi.get(`save-admin-company-doc-verified-status`, {
          params: {
            emp_id: emp_id,
            compId: company_id,
            verifiedStatus: verifiedStatus,
            verifiedRemarks: verifiedRemarks,
          }
        });

        if(response.data.status === 200)
        {
          showPopup(response.data.message, false);
          closeAttemptsModal();
          getCompanyDetailsList();
          //return;
        }
        else
        {
          showPopup(response.data.message, true);
          //return;
        }
      }
      catch (error)
      {
        showPopup(error, true);
        //return;
      }
    }

    const showPopup = (message, error) => {
      setPopupMessage(message);
      setIsError(error);
      setTimeout(() => {
        setPopupMessage("");
      }, 5000);
    };

   

    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                  <LoadingModal isLoading={isLoading} />
                    <div className="row">
                        <div class="col-sm-12">
                            <AdminDetailsHeader></AdminDetailsHeader>
                        </div>
                    </div>

                  {/* <div className="  d-flex flex-wrap align-items-end px-0" >
                    <div className="col-sm-3 col-6 p-1 py-2">
                      <small>Date From</small>
                      <input type="date" className="form-control" value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} />
                    </div>

                    <div className="col-sm-3 col-6 p-1 py-2">
                      <small>Date To</small>
                      <input type="date" className="form-control" value={dateTo} onChange={(e) => setDateTo(e.target.value)} />
                    </div>

                    <div className="col-sm-3 col-12 p-1 py-2 px-4">
                      <button className="btn btn-primary btn-sm" onClick={getNewKycDetails}>Search</button>
                    </div>
                  </div> */}

                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr style={{fontWeight:'700'}}>
                        <td>S.No</td>
                        <td>Company Name</td>
                        <td>Mobile Number</td>
                        <td>Email Id</td>
                        <td>Status</td>
                        <td>Verify Status</td>
                        <td>Executive Name</td>
                        <td>Created Date</td>
                        <td>Action</td>
                      </tr>
                      {companyDetails && companyDetails.map((cd,index)=>
                        <>
                          <tr>
                            <td>{index +1}</td>
                            <td>{cd.business_name}</td>
                            <td>{cd.mobile_no}</td>
                            <td>{cd.email}</td>

                            <td style={{fontWeight:'700'}}>
                              {cd.status==2?<span style={{color:'green'}}>Active</span>
                              :cd.status==3?<span style={{color:'red'}}>Inactive</span>
                              :cd.status==4?<span style={{color:'red'}}>Expire</span>
                              :''
                              }
                            </td>
                            <td>{cd.doc_verify_status==0?'Pending':'Completed'}</td>
                            <td>{cd.emp_name}</td>
                            <td>{cd.created_at}</td>
                            <td>
                              <button className="btn btn-primary btn-sm" onClick={()=>viewAttemptDetails(cd.comp_id,cd.business_name)}>Update Verified Status</button>
                            </td>
                          </tr>
                          
                        </>
                      )}

                      <tr>
                        <td colspan="11">
                        <Pagination className="custom-pagination">
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                            forcePage={currentPage}
                          />
                        </Pagination>
                        </td>
                      </tr>
                  </table>

                  {popupMessage && (
                  <div
                    style={{
                      position: "fixed",
                      bottom: "20px",
                      right: "20px",
                      padding: "10px 20px",
                      borderRadius: "5px",
                      backgroundColor: isError ? "red" : "green",
                      color: "white",
                      fontSize: "14px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      zIndex: 1000,
                    }}
                  >
                    {popupMessage}
                  </div>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />

      <Dialog open={attemptsModal} fullWidth PaperProps={{ style: { maxWidth: '30%', padding: '20px' } }}>
        <DialogTitle>Document Verified </DialogTitle>
          <DialogContent style={{height:"200px"}}>
            
                <div className="row">

                  <div className="col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                      <p style={{ margin: "0 10px" }}>{company_name}</p>
                      <div style={{ flex: 1, backgroundColor: "#17a2b8", height: "3px" }} />
                  </div>
                  
                  <div className="col-sm-12">
                    <label>Verified Status</label>
                    <select className="form-control" value={verifiedStatus} onChange={(e)=>setVerifiedStatus(e.target.value)}>
                      <option value=''>Select Status</option>
                      <option value="1">Approved</option>
                      <option value="2">Not Approved</option>
                    </select>
                      {validationErrors.verifiedStatus && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.verifiedStatus}</div>
                      )}
                  </div>

                  <div className="col-sm-12">
                    <label>Verified Remarks</label>
                    <input type="text" className="form-control" value={verifiedRemarks} onChange={(e)=>setVerifiedRemarks(e.target.value)}/>
                      {validationErrors.verifiedRemarks && (
                        <div className="text-danger" style={{ fontSize: '15px' }}>{validationErrors.verifiedRemarks}</div>
                      )}
                  </div>
                </div>
           
          </DialogContent>

          <DialogActions>
            <Link className="btn btn-primary btn-sm" onClick={saveVerifiedStatus}>Save</Link>
            <Link className="btn btn-info btn-sm" onClick={closeAttemptsModal}>Close</Link>
          </DialogActions>
        </Dialog>

    </DashboardLayout>
  );
}
export default Admin_company_details;

import React from 'react';
import { Link, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
function ClientDetailsHeader() {
  let location = useLocation();
  let currentPage = location.pathname;

  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department_id');

  return (
 
   
    <div className='d-flex justify-content-between align-items-center w-100' style={{ overflowY: 'hidden' }} >
      <div className='d-flex'>
        <Link style={currentPage === "/guest-client-details" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/guest-client-details">
          Guest Client
        </Link>
      </div>
      <div className='d-flex'>
        <Link style={currentPage === "/verified-client-details" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/verified-client-details">
          Verified Client
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/active-client" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/active-client">
          Active Client
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/inactive-client" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/inactive-client">
          Inactive Client
        </Link>
      </div>


      <div className='d-flex'>
        <Link style={currentPage === "/expire-client" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/expire-client">
          Expire Client
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/today-followup" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/today-followup">
          Followup
        </Link>
      </div>

      {/* {emp_id  == 'RIMS278' || emp_id  == 'RIMS93' && */}
        <div className='d-flex'>
          <Link style={currentPage === "/client-request-details" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/client-request-details">
            Request Details
          </Link>
        </div>
      {/* } */}

      {/* <div className='d-flex'>
        <Link style={currentPage === "/left-followup" ? { backgroundColor: '#17A2B8' } : { backgroundColor: "" }} className="btn btn-primary my-2 float-end mx-1 max-content" to="/left-followup">
          Left Followup.
        </Link>
      </div> */}



    </div>
  );
}


export default ClientDetailsHeader;

import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import secureLocalStorage from "react-secure-storage";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import StockDetailsHeader from "layouts/common_components/stock_details_header";
import ProfileHeader from "layouts/common_components/profileheader.js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

function IdleReport() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const [stocklist, setStockList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage] = useState(10);
  const [idle_report,get_idle_report] = useState([]);
  const [taskdetails,settaskdetails] = useState('');
  const [statusdetails,setstatusdetails] = useState('');
  const [openmodal,setopenmodal] = useState('');
  const [details,get_details] = useState([]);

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, navigate]);

  useEffect(() => {
    fetchStock();
  }, []);

  const fetchStock = async (page = 0) => {
    try {
      const response = await dashboardApi.get('idle-time-report', {
        params: {
          per_page: postPerPage,
          page: page + 1,
          emp_id:emp_id
        }
      });

      if (response.data.status === 200) {
        const responseData = response.data.data;
        const stockdata = responseData.data || responseData;
        get_idle_report(stockdata);
        setNumber(response.data.last_page);
      } else {
        console.error('Error fetching data:');
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  };
  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
    fetchStock(selected.selected);
  };
  const view_details = async(emp_id,date)=>{
    try {
        const response = await dashboardApi.get('idle-time-details', {
          params: {
           
            emp_id:emp_id,
            date:date,
          }
        });
  
        if (response.data.status === 200) {
             get_details(response.data.data);
             setopenmodal(true);
        } else {
          console.error('Error fetching data:');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }



  }
  const close_model = ()=>{
    setopenmodal(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
            <div className="container">
            <div className="row">
            <div className='col-md-12'>
            <Link to = '/profile' className='btn btn-info btn-sm'>Back</Link>&nbsp;&nbsp;
            <Link to = '/idle-time-report' className='btn btn-info btn-sm'>Idle Time</Link>
             &nbsp; &nbsp;
             <Link to = '/break-time-report' className='btn btn-info btn-sm'>Break Time</Link>
             <center style={{paddingLeft:'140px',color:'red',fontSize:'18px'}}>Idle Time Report</center>
            </div>
            </div>
            </div>
              <div className="card-body">
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <div className="table-responsive">
                    <table className="table table-bordered table-hovered">
                      <thead>
                        <tr>
                          <td>S.No</td>
                          <td>Emp Name</td>
                          <td>Department</td>
                          <td>Date</td>
                          <td>Total Idle Time(in hours)</td>
                          <td>Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {idle_report.map((row, index) => (
                          <tr key={row.emp_id-row.date}>
                            <td>{index + 1}</td>
                            <td>{row.emp_fname} {row.emp_lame}</td>
                            <td>{row.department_name}</td>
                            <td>{row.date}</td>
                            <td>{row.total_time_in_hours}</td>
                            <td><button className='btn btn-info btn-sm' onClick={() => view_details(row.emp_id,row.date)}>Details</button></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {idle_report.length > 0 && (
                  <Pagination className="custom-pagination">
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={number}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                      pageLinkClassName={"page-link-style"}
                      breakLinkClassName={"break-link-style"}
                      previousLinkClassName={"prev-link-style"}
                      nextLinkClassName={"next-link-style"}
                    />
                  </Pagination>
                )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={openmodal}
        fullWidth
         maxWidth={false} 
         PaperProps={{
          style: {
           width: '1100px', 
           height: '100vh', 
           padding: '40px',
         }
       }}
>
  <DialogTitle>Idle Time Reports</DialogTitle>
  <DialogContent>
    <table className='table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>From</th>
          <th>To</th>
          <th>Time (in min)</th>
        </tr>
      </thead>
      <tbody>
        {details.map((row) => (
          <tr key={row.id}>
            <td>{row.date}</td>
            <td>{row.time_start_from}</td>
            <td>{row.time_end}</td>
            <td>{row.idle_time}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </DialogContent>
  <DialogActions>
    <button className="btn btn-danger" onClick={close_model}>
      Close
    </button>
  </DialogActions>
</Dialog>

    
      <Footer />
    </DashboardLayout>
  );
}

export default IdleReport;

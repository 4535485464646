import React from 'react';
import { Link, useLocation } from "react-router-dom";
function AdminDetailsHeader() 
{
    let location = useLocation();
    let currentPage = location.pathname;

  return (
    <div className='d-flex justify-content-between align-items-center w-100' style={{ overflowY: 'hidden' }}>

      <div className='d-flex'>
        <Link style={currentPage === "/admin" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin">
          New Kyc Documents/Packages
        </Link>
      </div>
      
      <div className='d-flex'>
        <Link style={currentPage === "/admin-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin-client-details">
          Client Details
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/admin-company-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin-company-details">
          Company Details
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/admin-package-history" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin-package-history">
          Package History
        </Link>
      </div>

      {/* <div className='d-flex'>
        <Link style={currentPage === "/admin-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin-client-details">
          Package History
        </Link>
      </div>

      <div className='d-flex'>
        <Link style={currentPage === "/admin-client-details" ?{ backgroundColor:'#17a2b8'}:{backgroundColor:""}} className="btn btn-primary mb-2 float-end"  to="/admin-client-details">
          Client Info
        </Link>
      </div> */}
    </div>
  );
}


export default AdminDetailsHeader;

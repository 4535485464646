import React, { useState, useEffect, useRef } from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";
// Data
import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import dashboardApi from "layouts/common_components/apibase_url";
import { useNavigate } from 'react-router-dom';
import ClientDetailsHeader from 'layouts/common_components/client_details_header';
import secureLocalStorage from "react-secure-storage";
import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import 'layouts/sales/moving/followupStyle.css';
import DescriptionPopup from "layouts/common_components/description_popup.css";

function ExpireClient() {
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clientDetails, setClientDetails] = useState([]);
  const [exeDetails, setExeDetails] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [number, setNumber] = useState(1);
  const [postPerPage, setPostPerPage] = useState(10);

  const [selectedMobileNo, setClientMobileNumber] = useState();
  const [selectedCompanyName, setClientCompanyName] = useState();
  const [groupDetails, setGroupDetails] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const isInitialMount = useRef(false);
  const [selectedClientType, setClientType] = useState("recent");
  const [recentStatus, setRecentStatus] = useState("");
  const [monthStatus, setMonthStatus] = useState("");
  const [moreThanMonthStatus, setMorethanMonthStatus] = useState("");
  const [moreThan6MonthStatus, setMorethan6MonthStatus] = useState("");
  const [moreThan1YearStatus, setMorethan1YearStatus] = useState("");
  const [moreThan2YearStatus, setMorethan2YearStatus] = useState("");
  const [moreThan3YearStatus, setMorethan3YearStatus] = useState("");
  const [expireClientCount, setExpireClientCount] = useState({});


  const [product, setproduct] = useState('');
  const [productlist, setproductlist] = useState([]);
  const [servicelist, setservicelist] = useState([]);
  const [service, setservice] = useState('');
  const [category_list, set_category_list] = useState([]);
  const [category, selectedcategory] = useState('');
  const [hoveredRowData, setHoveredRowData] = useState(null);
  const [fatchedMessage, setDataFatchedMessage] = useState('');
  const onmouseover = (e) => {
    setHoveredRowData(e);
  }
  const onmouseleave = (e) => {
    setHoveredRowData('');
  }

  const array = `How to Search on This Page :"<br />"
    To find the information you need, follow these steps: <br/>
    - Select Criteria: Choose from the available options like Expired Clients for 1 Month, 2 Months, 1 Year, or 2 Years listed at the top of the page. <br/>
    - Apply Filters (Optional): Narrow your search further by selecting filters such as Product, Category, or Name. You can select either one filter or multiple filters together. <br/>
    - Hit the Search Button: Once you have made your selections, click the Search button to view the results matching your choices.`;


  useEffect(() => {
    getExeDetails();
    getGroupDetails();
    productdetails();
    setActiveStatus('recent');
    //getClientDetails();
  },[]);

  useEffect(() => {
    getClientDetails();
  }, [currentPage]);

  const productdetails = async () => {
    try {
      const endpoint = 'get-product-list';
      const response = await dashboardApi.get(endpoint);

      if (response.data.status === 200) {
        setproductlist(response.data.data);
      } else {
        console.error('Error fetching data:');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (product) {
      const fetchData = async () => {
        try {
          const endpoint = `get-service-list-by-product-id/${product}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            setservicelist(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [product]);

  useEffect(() => {
    if (service) {
      const fetchData = async () => {
        try {
          const endpoint = `get-category-list-by-service-id/${service}`;
          const response = await dashboardApi.get(endpoint);

          if (response.data.status === 200) {
            set_category_list(response.data.data);
          } else {
            console.error('Error fetching data:', response.data.message);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [service]);

  const getClientDetails = async () => {
    setLoading(true);
    getExpireClientCount();
    try {
      const response = await dashboardApi.get('get-expire-client-details', {
        params: {
          emp_id: emp_id,
          selectedEmp :selectedEmp,
          mobileNo: selectedMobileNo,
          companyName: selectedCompanyName,
          selectedGroup: selectedGroup,
          product: product,
          service: service,
          selectedClientType: selectedClientType,
          per_page: postPerPage,
          page: currentPage + 1,
        },
      });

      if (response.data.status === 200) {
        setClientType(response.data.clientType);
        const responseData = response.data.data;
        const clientData = responseData.data || responseData;
        setClientDetails(clientData);
        
        setNumber(responseData.last_page);
        setActiveStatus(response.data.clientType)
        setClientType(response.data.clientType);
        
        if(clientData.length < 1)
        {
          setDataFatchedMessage('The client could not be found based on the specified filters or might have a follow-up with another executive..');
        }
        setLoading(false);
      }
      else
      {
       
        console.error('Error fetching client details:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching client details:', error);
      setLoading(false);
    }
  };



  const getExpireClientCount = async () => {
    try {
      const response = await dashboardApi.get(`get-expire-client-count`, {
        params: {
          emp_id: selectedEmp,
          selectedGroup: selectedGroup,
          product: product,
          service: service,
          selectedClientType: selectedClientType,
        }
      });
      if (response.data.status === 200) {
        setExpireClientCount(response.data.data);

      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const getGroupDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-group-list`, {
        params: {
          emp_id: emp_id,
        }
      });
      if (response.data.status === 200) {
        setGroupDetails(response.data.data);

      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }






  const getExeDetails = async () => {
    try {
      const response = await dashboardApi.get(`get-executive-details/${emp_id}`);
      if (response.data.status === 200) {
        setExeDetails(response.data.data);
      } else {
        console.error('Error fetching countries:', response.data.message);
      }
      setLoading(false);
    }
    catch (error) {
      console.error('Error fetching countries:', error);
      setLoading(false);
    }
  }

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };



  const setActiveStatus = (clientType) => {
    setClientType(clientType);
    if (clientType === "recent") {
      setRecentStatus("active");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("");
    }
    else if (clientType === "month") {
      setRecentStatus("");
      setMonthStatus("active");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("");
    }
    else if (clientType === "abovemonth") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("active");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("");
    }
    else if (clientType === "6month") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("active");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("");
    }
    else if (clientType === "1year") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("active");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("");
    }
    else if (clientType === "2year") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("active");
      setMorethan3YearStatus("");
    }
    else if (clientType === "3year") {
      setRecentStatus("");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
      setMorethan3YearStatus("active");
    }
    else {
      setRecentStatus("active");
      setMonthStatus("");
      setMorethanMonthStatus("");
      setMorethan6MonthStatus("");
      setMorethan1YearStatus("");
      setMorethan2YearStatus("");
    }
  }


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Card>
          <div className="card-body p-2">
            <div className="d-flex justify-content-between align-items-center">
              <ClientDetailsHeader></ClientDetailsHeader>
            </div>



            <div className="col-sm-12 d-flex" style={{ overflowY: "hidden" }}>
              <div className="p-1">
                <button
                  className={`btn btn-primary small tabss ${recentStatus}`}
                  onClick={() => {
                    setActiveStatus("recent");
                    setCurrentPage(0);
                  }}
                  style={{ width: "max-content" }}
                >
                  Recent
                  <small
                    className="position-relative bg-danger px-1 text-white rounded"
                    style={{ left: "6px", bottom: "6px" }}
                  >
                    {expireClientCount.recent_expire}
                  </small>
                </button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${monthStatus}`} onClick={() => {
                  setActiveStatus("month");
                  setCurrentPage(0);

                }} style={{ width: "max-content" }}>Monthly
                  <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.month_expire}</small>
                </button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThanMonthStatus}`} onClick={() => {
                  setActiveStatus("abovemonth");
                  setCurrentPage(0);
                }} style={{ width: "max-content" }}>More Than Month <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.over_month_expire}</small></button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThan6MonthStatus}`} onClick={() => {
                  setActiveStatus("6month");
                  setCurrentPage(0);
                }} style={{ width: "max-content" }}>More Than 6 Month <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.over_6_month_expire}</small></button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThan1YearStatus}`} onClick={() => {
                  setActiveStatus("1year");
                  setCurrentPage(0);
                }} style={{ width: "max-content" }}>More Than 1 Year <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.over_1_year_expire}</small></button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThan2YearStatus}`} onClick={() => {
                  setActiveStatus("2year");
                  setCurrentPage(0);
                }} style={{ width: "max-content" }}>More Than 2 Year <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.over_2_year_expire}</small></button>
              </div>

              <div className="p-1">
                <button className={`btn btn-primary small tabss ${moreThan3YearStatus}`} onClick={() => {
                  setActiveStatus("3year");
                  setCurrentPage(0);
                }} style={{ width: "max-content" }}>More Than 3 Year <small className="position-relative bg-danger px-1 text-white rounded" style={{ left: "6px", bottom: "6px" }}>{expireClientCount.over_3_year_expire}</small></button>
              </div>
            </div>

            <div className='d-flex flex-wrap px-sm-0 px-2 '>
              <div className='col-sm-2 d-flex my-2 flex-column  px-sm-2 px-1'>
                <small className='m-0'>Length</small>
                <select className="form-control"
                  value={postPerPage}
                  onChange={(e) => setPostPerPage(e.target.value)}
                >
                  <option value='10'>10</option>
                  <option value='20'>20</option>
                  <option value='30'>30</option>
                  <option value='50'>50</option>
                  <option value='100'>100</option>
                  <option value='200'>200</option>
                  <option value='500'>500</option>
                </select>
              </div>

              <div className='col-sm-2 col-12 px-sm-2 py-2 px-0'>
                <small>Mobile No</small>
                <input type="text" className='form-control' style={{ width: '100%', padding: '8px', fontSize: '11px' }} value={selectedMobileNo} onChange={(e) => { setClientMobileNumber(e.target.value); setCurrentPage(0) }} />
              </div>
              <div className='col-sm-2 col-12 px-sm-2 py-2 px-0'>
                <small>Company Name</small>
                <input type="text" className='form-control' style={{ width: '100%', padding: '8px', fontSize: '11px' }} value={selectedCompanyName} onChange={(e) => { setClientCompanyName(e.target.value); setCurrentPage(0) }} />
              </div>
              <div className='col-sm-2 col-12 px-sm-2 py-2 px-0'>
                <small>Select Executive</small>
                <select className="form-control"
                  value={selectedEmp}
                  onChange={(e) => {
                    setSelectedEmp(e.target.value);
                    setCurrentPage(0);
                  }}
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value=''>Select Executive</option>
                  {exeDetails.map((ed) => (
                    <option key={ed.emp_id} value={ed.emp_id}>
                      {ed.emp_fname} {ed.emp_lname}
                    </option>
                  ))}
                </select>
              </div>

              <div className='col-sm-2 col-12 px-sm-2 py-2 px-0'>
                <small>Select Group</small>
                <select className="form-control"
                  value={selectedGroup}
                  onChange={(e) => {
                    setSelectedGroup(e.target.value);
                    setCurrentPage(0);
                  }}
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value=''>Select Group</option>
                  {groupDetails && groupDetails.map((gd) => (
                    <option key={gd.group_id} value={gd.group_id}>
                      {gd.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-2 col-12 px-sm-2 py-2 px-0">
                <small>Select Product</small>
                <select
                  className="form-control"
                  value={product}
                  onChange={(e) => setproduct(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Product</option>
                  {productlist.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.product_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-2 col-12 px-sm-2 py-2 px-0">
                <small>Select Service</small>
                <select
                  className="form-control"
                  value={service}
                  onChange={(e) => setservice(e.target.value)}
                  aria-label="Default select example"
                  style={{ width: '100%', padding: '8px', fontSize: '11px' }}
                >
                  <option value="">Select Service</option>
                  {servicelist.map((ser) => (
                    <option key={ser.id} value={ser.id}>
                      {ser.service_name}
                    </option>
                  ))}
                </select>
              </div>



              <div className='col-sm-2 d-flex my-2 flex-column justify-content-end  px-sm-2 px-1'>
                <span className="btn btn-primary btn-sm" onClick={getClientDetails}>Search</span>
              </div>
              <div className="col-sm-8 col-12 d-flex   p-1 justify-content-end">
                <strong className='text-danger d-flex align-items-center' onMouseEnter={() => onmouseover(array)} onMouseLeave={onmouseleave}>
                  <span class="material-icons-outlined px-2" >
                    info 
                  </span>
                  Instructions
                  {
                    hoveredRowData && hoveredRowData == array &&
                    // <DescriptionPopup data={array} />
                    <small className='small-cards-mypopup p-2 text-white'>
                      How to Search :<br />
                      - Choose Criteria: Select options like Expired Clients for 1 Month, 2 Months, 1 Year, or 2 Years from the top of the page. <br />
                      - Use Filters (Optional): You can also filter by Product, Category, or Name for a more specific search. <br />
                      - Click Search: Press the Search button to see the results based on your selections.
                    </small>
                  }
                </strong>

              </div>
            </div>



            {loading ? (
              <p style={{ align: 'center' }}>
                <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{ height: '80px', width: 'auto' }}></img>
              </p>
            ) : (
              <div className='p-2' style={{ overflowY: "hidden" }}>
                {/* <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>

                  <tr>
                    <td>S.No</td>
                    <td>Client Info</td>
                    <td>Company Info</td>
                    <td>Product</td>
                    <td>Service</td>
                    <td>Group Name</td>
                    <td>City Name</td>
                    <td>Status</td>
                    <td>Executive</td>
                    <td>Action </td>
                  </tr>

                  {clientDetails && clientDetails.map((cd, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{cd.client_name}<br />{cd.mobile_no} <br /> {cd.email}<br />{cd.client_status == 1 ? 'Veryfied' : cd.client_status == 2 ? 'Active' : cd.client_status == 3 ? 'Inactive' : cd.client_status == 4 ? 'Expire' : cd.client_status == 5 ? 'BlackList' : cd.client_status == 6 ? 'isDeleted' : ''}</td>
                      <td>{cd.business_name}<br />{cd.comp_mobile_no} <br /> {cd.comp_email}
                        <br />{cd.status == 1 ? 'Veryfied' : cd.status == 2 ? 'Active' : cd.status == 3 ? 'Inactive' : cd.status == 4 ? 'Expire' : cd.status == 5 ? 'BlackList' : cd.status == 6 ? 'isDeleted' : ''}
                      </td>
                      <td>{cd.product_name}</td>
                      <td>{cd.service_name}</td>
                      <td>{cd.group_name}</td>
                      <td>{(cd.city_name != '' && cd.city_name != null) ? cd.city_name : cd.city}</td>
                      <td>{cd.status === 4 ? 'Expire' : ''}</td>
                      <td>{cd.emp_name}</td>
                      <td>
                        <Link className="btn btn-primary mb-2 float-end" to={`/mature-client-profile/${cd.client_id}`}>
                          <span class="material-icons-outlined" alt='Profile'>manage_accounts</span>
                        </Link>
                      </td>
                    </tr>
                  ))
                  }
                  {clientDetails && clientDetails.length < 1 &&
                    <tr><td colspan="10" style={{color:"red",fontWeight:'7000',textAlign:'center'}}><span>The client could not be found based on the specified filters. Please verify the provided criteria and try again with different or more accurate filter values.</span></td></tr>
                  }
                </table> */}

                <table className="table table-bordered table-hovered" style={{ marginTop: '20px' }}>
                      <tbody>
                        <tr>
                          <td>S.No</td>
                          <td>Client Info</td>
                          <td>Company</td>
                          <td>Mobile</td>
                          <td>Email</td>
                          <td>Product</td>
                          <td>Service</td>
                          <td>Group Name</td>
                          <td>City Name</td>
                          <td>Status</td>
                          <td>Executive</td>
                          <td>Action </td>
                        </tr>
                        {clientDetails && clientDetails.length > 0 ? (
                          clientDetails.map((cd, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {cd.client_name}
                                <br />
                                {cd.mobile_no} <br />
                                {cd.email}
                                <br />
                                {cd.client_status == 1
                                  ? "Verified"
                                  : cd.client_status == 2
                                  ? "Active"
                                  : cd.client_status == 3
                                  ? "Inactive"
                                  : cd.client_status == 4
                                  ? "Expired"
                                  : cd.client_status == 5
                                  ? "Blacklisted"
                                  : cd.client_status == 6
                                  ? "Deleted"
                                  : ""}
                              </td>
                              <td>{cd.business_name}
                                <br />{cd.status == 1 ? 'Verified' : cd.status == 2 ? 'Active' : cd.status == 3 ? 'Inactive' : cd.status == 4 ? 'Expired' : cd.status == 5 ? 'Blacklisted' : cd.status == 6 ? 'Deleted' : ''}
                              </td>
                              <td>{cd.comp_mobile_no}</td>
                              <td>{cd.comp_email}</td>

                              <td>{cd.product_name}</td>
                              <td>{cd.service_name}</td>
                              <td>{cd.group_name}</td>
                              <td>{(cd.city_name !== "" && cd.city_name !== null) ? cd.city_name : cd.city}</td>
                              <td>{cd.status === 4 ? "Expired" : ""}</td>
                              <td>{cd.emp_name}</td>
                              <td>
                                <Link
                                  className="btn btn-primary mb-2 float-end"
                                  to={`/mature-client-profile/${cd.client_id}`}
                                >
                                  <span className="material-icons-outlined" alt="Profile">
                                    manage_accounts
                                  </span>
                                </Link>
                              </td>
                            </tr>
                          ))
                        ) : !loading ? (
                          
                          <tr>
                            <td colSpan="12" style={{ color: "red", fontWeight: "bold", textAlign: "center" }}>
                              <span>
                                {fatchedMessage}
                              </span>
                            </td>
                          </tr>
                        ) : null}
                      </tbody>
                    </table>
                <Pagination className="custom-pagination">
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={number}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                    pageLinkClassName={"page-link-style"}
                    breakLinkClassName={"break-link-style"}
                    previousLinkClassName={"prev-link-style"}
                    nextLinkClassName={"next-link-style"}
                    forcePage={currentPage}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </Card>
      </MDBox >
      <Footer />
    </DashboardLayout >
  );
}
export default ExpireClient;

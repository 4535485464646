import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import dashboardApi from "layouts/common_components/apibase_url";
function ProfileHeader() {
  //let emp = props.emp_id;
  const [openModal, setOpenModal] = useState(false);
  const dept_id = secureLocalStorage.getItem('department_id');
  //alert(dept_id);
  const emp_id = secureLocalStorage.getItem('emp_id');
  const [profiledata, getprofiledata] = useState({});
  const [leavedata, setleavedata] = useState('');
  const [wfhdata, setwfhdata] = useState('');
  const [roster, setroster] = useState('');
  const [attendance, setattendance] = useState();
  const permission = secureLocalStorage.getItem('permission');
  const parsedPermission = permission ? JSON.parse(permission) : [];
  const send_opening_request = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "send opening request");
  const opening_request_approval = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "opening request Approval");
  const view_opening_request = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "opening request details");
  const opening_request_followup = Array.isArray(parsedPermission) && parsedPermission.some(item => item.module === "Add Opening Followup")
  const view_profile = async () => {
    try {
      const response = await dashboardApi.get(`emp-profile-details/${emp_id}`);
      //console.log(response.data.data.department);
      if (response.data.status == 200) {
        //console.log('kkk');
        getprofiledata(response.data.data);
      } else {
        console.error('Error fetching employee profile:', response.data.message);
      }
    } catch (error) {
      console.error('Error fetching employee profile:', error);
    }


    setOpenModal(true);
  }

 


  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = `team-data-count/${emp_id}`;
        const response = await dashboardApi.get(endpoint);

        if (response.data.status === 200) {
          setwfhdata(response.data.wfh);
          setleavedata(response.data.leave);
          setroster(response.data.roster);
          setattendance(response.data.attendance_request);
        } else {
          console.error('Error fetching data:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  ///console.log(leavedata);
  //console.log(wfhdata);


  const handleclose = () => {
    setOpenModal(false);
  }
  // console.log(profiledata);

  return (
    <div className="card-body">

      <Link to={"/create-roster"} className='btn btn-info m-1 btn-sm'>
        Add Roster
      </Link>



      <Link to={"/emp-roster"} className='btn btn-info m-1 btn-sm'>
        View Roster
      </Link>



      <Link to={"/wfh-list"} className='btn btn-info m-1 btn-sm'>
        Team WFH 
      </Link>



      <Link to={"/apply-wfh"} className='btn btn-info m-1 btn-sm'>
        WFH
      </Link>



      <Link to={"/emp-leave"} className='btn btn-info m-1 btn-sm'>
        Apply Leaves
      </Link>


      <Link to={"/view-team"} className='btn btn-info m-1 btn-sm'>
        View Team
      </Link>


      <Link to={"/leave-details"} className='btn btn-info m-1 btn-sm'>
        Leave Request 
      </Link>



      <Link to={"/roster-change-request"} className='btn btn-info m-1 btn-sm'>
        Roster Change Request 
      </Link>

       {
         dept_id == 9 && 
        <Link to={"/job-opening-request"} className='btn btn-info m-1 btn-sm' >
          Opening Request

        </Link>

       }

      {
         dept_id == 5 && 
        <Link to={"/position-request"} className='btn btn-info m-1 btn-sm' >
          New Opening List

        </Link>
      }

       {
         dept_id == 5 && 
        <Link to={"/followup-details"} className='btn btn-info m-1 btn-sm' >
           Opening Request For You

        </Link>
      }

      <Link to={'/emp-target-list'} className='btn btn-info m-1 btn-sm' >
        Employee Target
      </Link>


      <button className='btn btn-info m-1 btn-sm' onClick={view_profile}>
        View Profile
      </button>


      <Link to='/team-resign-list' className='btn btn-info m-1 btn-sm' >
        Team Resign Details
      </Link>

      <Link to='/idle-time-report' className='btn btn-info m-1 btn-sm' >
       Idle/Break Time Report
      </Link>

      <Dialog
        open={openModal}
        onClose={handleclose}
        fullWidth
        PaperProps={{ style: { maxWidth: 'xl', padding: '40px', height: '1900x' } }}
      >
        <DialogTitle>Profile Details</DialogTitle>
        <DialogContent>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <b>Emp Name</b>:
                <p>{profiledata.emp_fname} {profiledata.emp_mname} {profiledata.emp_lame}</p>

              </div>
              <div className="col-md-6">
                <b>Father Name</b>:
                <p>{profiledata.father_name}</p>

              </div>

            </div>
            <div className="row">
              <div className="col-md-6">
                <b>Department</b>:
                <p>{profiledata.department}</p>

              </div>
              <div className="col-md-6">
                <b>Designation</b>:
                <p>{profiledata.designation}</p>

              </div>

            </div>
            <div className="row">
              <div className="col-md-6">
                <b>Reporting Manager</b>:
                <p>{profiledata.manager}</p>

              </div>
              <div className="col-md-6">
                <b>Date Of Join</b>:
                <p>{profiledata.doj}</p>

              </div>

            </div>
            <div className="row">
              <div className="col-md-6">
                <b>Date Of Confirmation</b>:
                <p>{profiledata.doc}</p>

              </div>

            </div>
          </div>

        </DialogContent>
        <DialogActions>
          <button className="btn btn-danger" onClick={handleclose}>
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>

  );
}


export default ProfileHeader;

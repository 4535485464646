import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import dashboardApi from "layouts/common_components/apibase_url";
import "./attendance_report.css";
import Swal from "sweetalert2";

function Add_salary() {
  const token = secureLocalStorage.getItem("token");
  const emp_id = secureLocalStorage.getItem("emp_id");
  const [department, setDepartment] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [emp_status, setEmpStatus] = useState("");
  const [attribute_list, setAttributeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [attributeValues, setAttributeValues] = useState({});
  const [ctc,set_ctc] = useState('');
  const [isMetrocity, setIsMetrocity] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
    }
  }, [token, emp_id, navigate]);

  // Fetch Active Departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await dashboardApi.get("get-active-department");
        if (response.data.status === 200) {
          setDepartment(response.data.data);
        } else {
          console.error("Error fetching departments:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };
    fetchDepartments();
  }, []);

  // Fetch Salary Attributes
  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await dashboardApi.get("get-active-salary-attribute");
        if (response.data.status === 200) {
          setAttributeList(response.data.data);
        } else {
          console.error("Error fetching attributes:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching attributes:", error);
      }
    };
    fetchAttributes();
  }, []);
  useEffect(() => {
    const fetchEmployees = async () => {
      if (!selectedDepartment || !emp_status) return;

      try {
        const response = await dashboardApi.post(
          "get-emp-list-based-on-status-and-department",
          {
            department: selectedDepartment,
            status: emp_status,
          }
        );

        if (response.data.status === 200) {
          setEmployeeList(response.data.data);
        } else {
          console.error("Error fetching employees:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      }
    };

    fetchEmployees();
  }, [selectedDepartment, emp_status]);
  const handleAttributeChange = (attributeId, value) => {
    setAttributeValues((prevValues) => ({
      ...prevValues,
      [attributeId]: value,
    }));
  };

  const handleSave = async () => {
    if (!selectedEmployee) {
      alert("Please select an employee!");
      return;
    }

    const salaryData = Object.entries(attributeValues).map(([attributeId, amount]) => ({
      attribute_id: attributeId,
      amount: amount || 0, 
    }));
  
    const payload = {
      emp_id: selectedEmployee,
      salary_details: salaryData,
      ctc:ctc,
      is_metrocity:isMetrocity,
      created_by:emp_id,
    };
  
    try {
      const response = await dashboardApi.post("save-salary-details", payload);
      if (response.data.status === 200) {
           Swal.fire({
            icon: 'success',
            text: response.data.message,
          });
      } else {
          Swal.fire({
            icon: 'error',
            text: response.data.message,
          });
      }
    } catch (error) {
      console.error("Error saving salary details:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="container-fluid">
                <div className="row">
                  {/* Department Dropdown */}
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                    >
                      <option value="">Select Department</option>
                      {department.map((dept) => (
                        <option key={dept.id} value={dept.id}>
                          {dept.department_name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Employee Status Dropdown */}
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      value={emp_status}
                      onChange={(e) => setEmpStatus(e.target.value)}
                    >
                      <option value="">Select Emp Type</option>
                      <option value="1">Active</option>
                      <option value="0">Inactive</option>
                    </select>
                  </div>

                  {/* Employee List Dropdown */}
                  <div className="col-md-3">
                    <select
                      className="form-control"
                      value={selectedEmployee}
                      onChange={(e) => setSelectedEmployee(e.target.value)}
                    >
                      <option value="">Select Employee</option>
                      {employeeList.map((emp) => (
                        <option key={emp.emp_id} value={emp.emp_id}>
                          {emp.emp_fname} {emp.emp_lame} ({emp.emp_id})
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <hr />
                <div className="row">
                <div className="col-md-3">
                <label style={{fontSize:'16px'}}>CTC</label>
                <input type='number'
                  className="form-control"
                  placeholder="Enter CTC"
                  value={ctc}
                  onChange={(e) =>set_ctc(e.target.value)}
                
                />
                </div>

                <div className="col-md-3">
                <label style={{fontSize:'16px'}} className="ms-2">
                  Is Metrocity Employee
                 </label>
                 <input
                   type="checkbox"
                   id="metrocityCheckbox"
                   className="form-check-input form-control"
                   checked={isMetrocity === 1}
                   onChange={(e) => setIsMetrocity(e.target.checked ? 1 : 0)}
                  />
                 </div>
                 </div>
                <hr />

                <div className="row">
                  {attribute_list.map((attr, index) => (
                    <div key={attr.id} className="col-md-4 mb-3">
                     <label style={{ fontSize: '16px' }}>
                        {attr.attribute} {attr.is_metrocity_attribute === 1 ? "(Metrocity)" : ""}- <span style={{color:'red'}}>per anum</span>
                    </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter Amount"
                        value={attributeValues[attr.id] || ""}
                        onChange={(e) =>
                          handleAttributeChange(attr.id, e.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn-primary mt-3"
                      onClick={handleSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>

              <MDBox p={3}></MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Add_salary;

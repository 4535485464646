import React,{useState,useEffect} from 'react';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import DashboardLayout from "theme_layout/LayoutContainers/DashboardLayout";
import DashboardNavbar from "theme_layout/Navbars/DashboardNavbar";
import Footer from "theme_layout/Footer";

import { Link } from 'react-router-dom';
import "layouts/superadmin/components/global.css";
import Swal from 'sweetalert2';
import dashboardApi from "layouts/common_components/apibase_url";
import {useNavigate } from 'react-router-dom';

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CsDetailsHeader from 'layouts/common_components/cs_details_header';

import { TextField } from '@mui/material';
import { Button } from 'react-bootstrap';
import  secureLocalStorage  from  "react-secure-storage";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from 'react-bootstrap/Pagination';
import ReactPaginate from 'react-paginate';
import "layouts/css/global.css";

function Guest_user() 
{
  const navigate = useNavigate();
  const emp_id = secureLocalStorage.getItem('emp_id');
  const token = secureLocalStorage.getItem('token');
  const department = secureLocalStorage.getItem('department');
  const [loading, setLoading] = useState(true);
  const [notSentEnq,setNotSentEnq] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); 
  const [number, setNumber] = useState(1); 
  const [postPerPage] = useState(10);

    const closeApprovalControl = ()=>
    {
      setApprovalModal(false);
    }
    
    useEffect(() => {
      getAppEnq();
    }, [currentPage]);

    const getAppEnq = async () => 
    {
      try
      {
        const response = await dashboardApi.get(`get-app-user-enquiry`,{
          params:{
            emp_id:emp_id,
            per_page: postPerPage,
            page: currentPage + 1,
            mobileNumber:''
          }
          
        });
        if(response.data.status === 200)
        {
          const responseData = response.data.data;
          const customerData = responseData.data || responseData;
          setNumber(responseData.last_page);
          setNotSentEnq(customerData);
          setLoading(false);
        }
        else
        {
          console.error('Error fetching countries:', response.data.message);
        }
      }
      catch (error)
      {
        console.error('Error fetching countries:', error);
      }
    }

    const handlePageClick = (selected) => {
      setCurrentPage(selected.selected);
    };



    return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <div className="card-body">
                
                  <div className="row">
                      <div class="col-sm-11">
                          <CsDetailsHeader></CsDetailsHeader>
                      </div>
                      <div class="col-sm-1">
                        <Link className='btn btn-info' target="_blank" to="/add-enquiry">
                            +
                        </Link>
                      </div>
                  </div>

                  <div className="row" style={{padding: '9px'}}>
                    <div className="col-sm-4">
                      <a href="" className="btn btn-success btn-sm" style={{marginTop: '38px'}}>
                      <span class="material-icons-outlined">refresh
                      </span></a>
                    </div>
                  </div>

                  {loading ? (
                  <p style={{align:'center'}}>
                  <img src={`${process.env.PUBLIC_URL}/newfileloding.gif`} style={{height:'80px',width:'auto'}}></img>
                  </p>
                  ) : (
                  <div style={{overflowY: "hidden"}}>
             
                    <table className="table table-bordered table-hovered" style = {{width:'100%'}}>
                      <tr>
                        <td>S.No</td>
                        <td>Customer Name</td>
                        <td>Contact No</td>
                        <td>Email</td>
                        <td>City</td>
                        <td>State</td>
                        <td>Address</td>
                        <td>Created Date</td>
                        
                      
                      </tr>
                      {notSentEnq && notSentEnq.length > 0 ? (
                          notSentEnq.map((enq,index)=>
                          (
                        
                            <tr>
                              <td>{index + 1}</td>
                              <td>{enq.name}</td>
                              <td>{enq.primary_no}</td>
                              <td>{enq.email}</td>
                              <td>{enq.city}</td>
                              <td>{enq.state}</td>
                              <td>{enq.address}</td>
                              <td>{enq.added_date}</td>
                            </tr>
                          ))
                        ) : (
                          <tr><td colspan="12" style={{color:'red',align:'center'}}>No data found</td></tr>
                      )}
                      <tr>
                        <td colspan="11">
                          <Pagination className="custom-pagination">
                            <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={number}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link-style"}
                            breakLinkClassName={"break-link-style"}
                            previousLinkClassName={"prev-link-style"}
                            nextLinkClassName={"next-link-style"}
                          />
                          </Pagination>
                        </td>
                      </tr>
                    </table>
                  </div>
                  )}
              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />


    </DashboardLayout>
  );
}
export default Guest_user;
